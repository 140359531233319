import React from 'react';
import { Container } from 'react-bootstrap';
import { TopBar } from './TopBar';

export function Layout(props) {
    return (
        <Container fluid className="vh-100 d-flex overflow-hidden flex-column flex-row bg-light">
            <TopBar />
            <Container fluid className="row pt-5 overflow-auto ">
                {props.children}
            </Container>
        </Container>
    );
}