/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom"
import React, { useEffect, useContext, useState, useRef } from 'react';
import { GlobalContext } from './contexts/GlobalContext';
import { Loading } from './Loading';
import { ClimateImpactReport, InputDataReport } from './Reports';
import { ClimateChangeChart } from './ClimateChangeChart';
import { Product } from './Product';
import { Form, Col, Row, Button, Spinner, Table, Container } from "react-bootstrap";
import Papa from 'papaparse'
import { pdf } from '@react-pdf/renderer';
import { fetchReportTemplates, fetchImageNames, fetchParameters, fetchSimaproParameters, fetchInputDataReport, fetchMachineReadableReport, fetchWordReport, fetchPdfReport, fetchWeightingCsvReport, addProduct, updateProduct, generateSimaproReport, generateInputDataReport, uploadClimateImpactReport, fetchClimateImpactReport, fetchClimateImpactCsvReport, fetchItems, syncParametersWithSimapro } from '../Queries'
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { getExtGroupValue, getGroupedExtParameters, getDateTimeNow } from '../Common'
import fileDownload from 'js-file-download'
import { useNotifications } from '@de/react-bootstrap-notifier';

export function InputForm() {
    const navigate = useNavigate()
    const { addInfo, addWarning, addError } = useNotifications();
    const { user, currentProject, currentProduct, clearContextData, parameterParsingRegex } = useContext(GlobalContext)

    const [isValidationEnabled, setIsValidationEnabled] = useState(false)
    const [parameters, setParameters] = useState([]);
    const [enterParametersManually, setEnterParametersManually] = useState(true);
    const [enterParametersFromFile, setEnterParametersFromFile] = useState(false);
    const syncedWithSimapro = useRef(false);
    const climateChangeFossilChartRef = useRef(null);
    const climateChangeBiogenicChartRef = useRef(null);
    const climateChangeTotalChartRef = useRef(null);
    const sidelistFileRef = useRef("")
    const reportId = useRef("")

    const queryClient = useQueryClient()
    const parametersSimaproResult = useQuery({ queryKey: ['fetchSimaproParameters', currentProject.id], queryFn: () => fetchSimaproParameters(currentProject.id), enabled: false })
    const parametersDbResult = useQuery({ queryKey: ['fetchParameters', currentProduct?.id], queryFn: () => fetchParameters(currentProduct?.id), enabled: !!currentProduct?.id, refetchOnWindowFocus: false })
    const itemsResult = useQuery({ queryKey: ['fetchItems', currentProject.id], queryFn: () => fetchItems(currentProject.id), enabled: !!currentProject?.id })
    const reportTemplatesResult = useQuery({ queryKey: ['fetchReportTemplates', currentProject.id], queryFn: () => fetchReportTemplates(currentProject.id), enabled: !!currentProject?.id })
    const imageFilenamesResult = useQuery({ queryKey: ['fetchImageNames', currentProject.id], queryFn: () => fetchImageNames(currentProject.id), enabled: !!currentProject?.id })

    const inputDataReportResult = useQuery({
        queryKey: ['fetchInputDataReport', currentProject.id],
        queryFn: () => fetchAndDownload(() => fetchInputDataReport(currentProject.id), "indata report.pdf"),
        enabled: false
    })

    const climateImpactReportResult = useQuery({
        queryKey: ['fetchClimateImpactReport', currentProject.id],
        queryFn: () => fetchAndDownload(() => fetchClimateImpactReport(currentProject.id), "climate impact report.pdf"),
        enabled: false
    })

    const climateImpactCsvReportResult = useQuery({
        queryKey: ['fetchClimateImpactCsvReport', currentProject.id],
        queryFn: () => fetchAndDownload(() => fetchClimateImpactCsvReport(currentProject.id), "climate impact.csv"),
        enabled: false
    })

    const weightingCsvReportResult = useQuery({
        queryKey: ['fetchWeightingCsvReport', currentProject.id],
        queryFn: () => fetchAndDownload(() => fetchWeightingCsvReport(currentProject.id), "weighting.csv"),
        enabled: false
    })

    const machineReadableReportResult = useQuery({
        queryKey: ['fetchMachineReadableReport', currentProject.id],
        queryFn: () => fetchAndDownload(() => fetchMachineReadableReport(currentProject.id), "machine readable report.xlsx"),
        enabled: false
    })

    const wordReportResult = useQuery({
        queryKey: ['fetchWordReport', currentProject.id],
        queryFn: () => fetchAndDownload(() => fetchWordReport(currentProject.id), "EPD report.docx"),
        enabled: false
    })

    const pdfReportResult = useQuery({
        queryKey: ['fetchPdfReport', currentProject.id],
        queryFn: () => fetchAndDownload(() => fetchPdfReport(currentProject.id), "EPD report.pdf"),
        enabled: false
    })

    const fetchAndDownload = async (fetchFunction, filename) => {
        try {
            const blob = await fetchFunction();
            fileDownload(blob, `${reportId.current} ${filename}`);
        } catch (error) {
            addError({ ...error.response.data, message: `An error occurred while downloading the file: "${filename}"!` })
        }
    }

    const addProductMutation = useMutation({
        mutationFn: async (product) => await addProduct({ ...product, parameters: parameters }),
        onSuccess: (_, variables) => {
            addInfo(`Product "${variables.name}" added successfully`);
            queryClient.invalidateQueries({ queryKey: ['fetchProducts', currentProject.id] })
        },
        onError: (error) => addError({ ...error.response.data, message: "An error occurred while adding product!" })
    })

    const updateProductMutation = useMutation({
        mutationFn: async (product) => await updateProduct({ ...product, parameters: parameters }),
        onSuccess: () => {
            addInfo("Product updated");
            queryClient.invalidateQueries({ queryKey: ['fetchProducts', currentProject.id] })
        },
        onError: (error) => addError({ ...error.response.data, message: "An error occurred while updating product!" })
    })

    const syncParametersWithSimaproMutation = useMutation({
        mutationFn: async (updatedParameters) => await syncParametersWithSimapro({ projectId: currentProject.id, parameters: updatedParameters }),
        onSuccess: () => {
            syncedWithSimapro.current = true;
            addInfo("All products synced with Simapro");
            queryClient.invalidateQueries({ queryKey: ['fetchProducts', currentProject.id] })
            queryClient.invalidateQueries({ queryKey: ['fetchParameters', currentProduct?.id] })
        },
        onError: (error) => addError({ ...error.response.data, message: "An error occurred while syncing parameters with Simapro!" })
    })

    const generateSimaproReportMutation = useMutation({
        mutationFn: async (input) => await generateSimaproReport(input),
        onSuccess: async (data) => {
            const onTimeout = async () => {
                await generateClimateImpactReport(data.data);
                const reportBlob = await pdf(InputDataReport(reportId.current, currentProduct, parameters, getGroupedExtParameters(parameters))).toBlob();
                let formData = new FormData();
                formData.append("fileForm", reportBlob, `${currentProject.id}_input_data_report.pdf`);

                await generateInputDataReportMutation.mutateAsync(formData)
            }
            setTimeout(onTimeout, 1000);
            addInfo("Report is ready")
        },
        onError: (error) => {
            if (error.response.data === "Top group")
                addError("Top Group is not zero. The model needs to be checked. Contact Tyrens.")
            else
                addError({ detail: error.response.data, message: "An error occurred while generating the report!" })
        }
    })

    const generateInputDataReportMutation = useMutation({
        mutationFn: async (formData) => await generateInputDataReport(formData),
        onSuccess: () => { handleSaveAll() },
    })

    const uploadClimateImpactReportMutation = useMutation({
        mutationFn: async (formData) => await uploadClimateImpactReport(formData)
    })

    useEffect(() => {
        if (parametersSimaproResult?.data?.length > 0)
            syncedWithSimapro.current = true

        const onBackButtonEvent = () => {
            clearContextData();
        }
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, []);

    useEffect(() => {
        if (parametersSimaproResult?.data && !syncedWithSimapro.current) {
            const combinedParameters = combineWithSimaproParameters(parameters);
            syncParametersWithSimaproMutation.mutate(combinedParameters)
        }
    }, [parametersSimaproResult.data, syncedWithSimapro.current])

    useEffect(() => {
        if (parametersSimaproResult.error) addError({ ...parametersSimaproResult.error.response.data, message: "An error occurred while fetching parameters!" })
    }, [parametersSimaproResult.error])

    useEffect(() => {
        if (parametersDbResult.data) {
            let copy = parametersDbResult.data
            combineWithSimaproParameters(copy)
        }
    }, [parametersDbResult.data])

    useEffect(() => {
        if (!currentProject?.id)
            navigate("/")
    }, [currentProject.id]);

    const parameterTypeIdFromName = {
        BOM: 1,
        PAC: 2,
        EXT: 3,
        OPT: 4
    }

    const setCommentForGroup = (group, newComment) => {
        const updatedParameters = parameters.map(param => {
            if (param.parameterTypeName === 'EXT' && String(param.parameterGroup) === group)
                param.comment = newComment

            return { ...param }
        })

        setParameters(updatedParameters);
    }

    const getValidationStatus = () => {
        const validationState =
            currentProduct?.name &&
            currentProduct?.identification &&
            currentProduct?.description &&
            currentProduct?.epdRegistrationNumber &&
            currentProduct?.unCpcCode &&
            currentProduct?.publicationDate &&
            currentProduct?.reportTemplateFilename &&
            reportTemplatesResult?.data.includes(currentProduct?.reportTemplateFilename) &&
            currentProduct?.imageFilename &&
            imageFilenamesResult?.data.includes(currentProduct?.imageFilename) &&
            (getGroupedExtParameters(parameters).length === 0 ||
                !!getGroupedExtParameters(parameters).find(group => group.value.map(x => x.calculatedValue).includes(1)))

        const status = !!validationState

        return status;
    };

    const generateClimateImpactReport = async (data) => {
        const fossilChartImage = climateChangeFossilChartRef.current !== null ? climateChangeFossilChartRef.current.toBase64Image() : null;
        const biogenicChartImage = climateChangeBiogenicChartRef.current !== null ? climateChangeBiogenicChartRef.current.toBase64Image() : null;
        const totalChartImage = climateChangeTotalChartRef.current !== null ? climateChangeTotalChartRef.current.toBase64Image() : null;
        const reportBlob = await pdf(ClimateImpactReport(reportId.current, currentProduct, data?.climateImpacts, data?.environmentalImpactIdicators, fossilChartImage, biogenicChartImage, totalChartImage)).toBlob();
        let formData = new FormData();
        formData.append("fileForm", reportBlob, `${currentProject.id}_climate_impact_report.pdf`);

        uploadClimateImpactReportMutation.mutate(formData)
    }

    const handleGenerateEpdReport = async () => {
        if (getValidationStatus()) {
            reportId.current = `EPD Calculation ${currentProject.name} ${getDateTimeNow()}`;
            const parametersToSend = parameters
                .filter(par => !(par.parameterTypeName === "EXT" && par.calculatedValue === 0))
                .map(p => ({ ...p, productId: currentProduct?.id }));

            currentProduct.id
                ? await updateProductMutation.mutateAsync(currentProduct)
                : await addProductMutation.mutateAsync({ ...currentProduct, projectId: currentProject.id });

            await generateSimaproReportMutation.mutateAsync({ projectId: currentProject.id, reportId: reportId.current, parameters: parametersToSend, product: { ...currentProduct, projectId: currentProject.id } });
        }
        else {
            setIsValidationEnabled(true)
            addWarning("Fill in all missing data")
        }
    }

    const handleParameterChange = (parameter, value, propertyName) => {
        if (parameter.parameterTypeName !== 'EXT') {
            const parameterIndex = parameters.findIndex((par => par.name === parameter.name && par.parameterTypeName === parameter.parameterTypeName));

            propertyName === "calculatedValue"
                ? parameters[parameterIndex][propertyName] = Number(value)
                : parameters[parameterIndex][propertyName] = value

            setParameters([...parameters]);
        }
    }

    const handleGroupCommentChange = (parameter, value) => {
        setCommentForGroup(parameter.key, value)
    }

    const handleExtSelectionChange = (group, value) => {
        const parameterIndex = parameters.findIndex((par => par.name === value && par.parameterTypeName === "EXT" && String(par.parameterGroup) === group));

        const updatedParams = parameters.map(par => {
            if (par.parameterTypeName === "EXT" && String(par.parameterGroup) === group) {
                par.calculatedValue = 0;
            }

            return { ...par }
        })
        updatedParams[parameterIndex].calculatedValue = 1

        setParameters([...updatedParams]);
    }

    const validateCsvInputFile = (results) => {

        const csvHeaders = Object.keys(results[0]);
        const missingHeaders = ['Material', 'Costing Lot Size'].filter(
            (header) => !csvHeaders.map(csvheader => csvheader.toLowerCase()).includes(header.toLowerCase())
        );

        if (missingHeaders.length > 0) {
            addWarning(
                <>
                    {"Missing headers:"} <br />
                    {missingHeaders.map(header =>
                        <div>
                            {header} <br />
                        </div>)}
                </>)

            return;
        }

        const filteredResults = results.filter(csvItem => !!csvItem['Sales Doc.'])
        const isValidInput = filteredResults.map(csvItem => csvItem.Material && !isNaN(String(csvItem['Costing Lot Size']).replace(",", ".")))
            ?.reduce((accumulator, value) => accumulator && value)

        if (!isValidInput) {
            addWarning(
                <>
                    Cancelled due to invalid input.<br />
                    Mandatory data for each column:<br />
                    'Material',<br />
                    'Costing Lot Size'<br />
                </>);
            return false
        }

        const missingItemsInMapper = new Set(
            filteredResults
                .filter(csvItem => !itemsResult.data.map(p => p.name).includes(String(csvItem.Material)))
                .map(csvItem => String(csvItem.Material))
        );

        if (missingItemsInMapper.size > 0) {
            addWarning(
                <div>
                    Items omitted due to absence in mapper:
                    <br />
                    {Array.from(missingItemsInMapper).slice(0, 10).map(name =>
                        <>
                            {name}<br />
                        </>
                    )}
                    {missingItemsInMapper.size > 10 ? "..." : ""}
                </div>);
            return true;
        }
    }

    const handleSelectCsv = (event) => {
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: 'greedy',
            delimiter: ";",
            dynamicTyping: true,
            complete: function (results) {

                if (!validateCsvInputFile(results.data))
                    return

                const filteredResults = results?.data?.filter(csvItem => !!csvItem['Sales Doc.'])
                const transformedParameters = filteredResults.map(csvItem => (
                    {
                        item: itemsResult?.data?.find(dbItem => dbItem.name === String(csvItem.Material))?.bomName,
                        factor: itemsResult?.data?.find(dbItem => dbItem.name === String(csvItem.Material))?.factor,
                        amount: parseFloat(String(csvItem['Costing Lot Size']).replace(",", "."))
                    })).filter(input => input.item)

                const groupedParameters = Object.groupBy(transformedParameters.map(x => {
                    x.groupKey = x.item

                    return { ...x }
                }), ({ groupKey }) => groupKey)

                const groupedTable = []

                for (const [key, value] of Object.entries(groupedParameters)) {
                    groupedTable.push(
                        {
                            name: key,
                            parameterTypeName: "BOM",
                            parameterTypeId: 1,
                            productId: currentProduct?.id,
                            comment: "",
                            calculatedValue: value
                                .map(element => element.amount * element.factor / 1000)
                                .reduce((accumulator, value) => accumulator + value, 0)
                        });
                }

                combineSidelistAndDb(groupedTable)
            },
        });
    }

    const combineSidelistAndDb = (sidelistData) => {
        const updatedParameters = sidelistData.map(parameter => {
            const dbParameter = parametersDbResult?.data?.find(dbParameter => (dbParameter.parameterTypeName === 'BOM' && dbParameter.name === parameter.name))

            const updatedParameter = dbParameter
                ? { ...dbParameter, calculatedValue: parameter.calculatedValue }
                : parameter;

            return updatedParameter;
        })

        setParameters(updatedParameters)
    }

    const combineWithSimaproParameters = (productParametersToMerge) => {
        if (parametersSimaproResult.data) {
            const combinedParameters = parametersSimaproResult.data.map(paramSimapro => {
                const parameterTypeNameSimapro = paramSimapro.name.match(parameterParsingRegex)[1];
                const parameterTypeIdSimapro = parameterTypeIdFromName[parameterTypeNameSimapro];
                const parameterGroupSimapro = paramSimapro.name.match(parameterParsingRegex)[2] === '' ? null : Number(paramSimapro.name.match(parameterParsingRegex)[2]);
                const nameSimapro = paramSimapro.name.match(parameterParsingRegex)[3];

                const parameterFromDb = productParametersToMerge.find(x => (x.parameterTypeName !== 'EXT' && x.name === nameSimapro) ||
                    (x.parameterTypeName === 'EXT' && x.parameterGroup === parameterGroupSimapro && x.name === nameSimapro));

                let comment = '';
                let calculatedValue = 0

                if (parameterFromDb) {
                    if (parameterTypeNameSimapro === 'EXT') {
                        comment = productParametersToMerge.find(parameter => parameter.parameterTypeName === 'EXT')?.comment ?? ""
                        calculatedValue = parameterFromDb.calculatedValue
                    }
                    else {
                        comment = parameterFromDb.comment
                        calculatedValue = Number(parameterFromDb.calculatedValue)
                    }
                }

                return {
                    "productId": currentProduct?.id,
                    "name": nameSimapro,
                    "parameterTypeName": parameterTypeNameSimapro,
                    "parameterTypeId": parameterTypeIdSimapro,
                    "parameterGroup": parameterGroupSimapro,
                    "calculatedValue": calculatedValue,
                    "comment": comment
                }
            })

            setParameters(combinedParameters);
            return combinedParameters
        }
        else {
            setParameters(productParametersToMerge)
            return productParametersToMerge
        }
    }

    const handleFetchFromSimapro = async () => {
        syncedWithSimapro.current = false
        await parametersSimaproResult.refetch();
    }

    const handleSaveAll = () => {
        pdfReportResult.refetch();
        inputDataReportResult.refetch();
        climateImpactReportResult.refetch();
        machineReadableReportResult.refetch();
        if (user?.role === 'Admin' || user?.isAdmin) {
            wordReportResult.refetch();
            climateImpactCsvReportResult.refetch();
            weightingCsvReportResult.refetch()
        }
    }

    const checkIfExtSelected = (group) => {
        const status = parameters.find((par =>
            group.value.map(x => x.name).includes(par.name)
            && par.parameterTypeName === "EXT"
            && par.calculatedValue === 1
        ))

        return !!status;
    }

    const handleValidateParameters = async () => {
        const parametersWithMissingBomInSimapro = new Set(
            parameters
                .filter(item => item.parameterTypeName === "BOM" && !parametersSimaproResult.data
                    .filter(p => (p.name.match(parameterParsingRegex)[1]) === "BOM")
                    .map(p => p.name.match(parameterParsingRegex)[3])
                    .includes(item.name))
                .map(item => item.name)
        );

        if (parametersWithMissingBomInSimapro.size > 0) {
            addWarning(
                <div>
                    Cancelled. Parameters not defined in Simapro:
                    <br />
                    {Array.from(parametersWithMissingBomInSimapro).slice(0, 10).map(name =>
                        <>
                            {name}<br />
                        </>
                    )}
                    {parametersWithMissingBomInSimapro.size > 10
                        ? "..."
                        : ""
                    }
                </div>);
            return;
        }
        else {
            addInfo("Parameters are correct")
        }
    }

    return (
        <Container>
            <fieldset disabled={generateSimaproReportMutation.isLoading}>
                <Row className="d-flex flex-row justify-content-center">
                    <Col className="justify-content-center"
                        xs={12}
                        sm={12}
                        md={12}
                        xl={6}
                        xxl={6}>
                        <div className="d-flex fw-bold h3">
                            <span >Product information</span>
                        </div>
                        <hr />

                        <Product parameters={parameters} combineWithSimaproParameters={combineWithSimaproParameters} isValidationEnabled={isValidationEnabled} setIsValidationEnabled={setIsValidationEnabled} />
                        <Form className="mt-5">
                            <Form.Group as={Row} className="d-flex mt-5" >
                                <Form.Check
                                    type="checkbox"
                                    id="manually"
                                    label="Enter amount material, packaging and other selection - Manually"
                                    checked={enterParametersManually}
                                    onChange={() => {
                                        setEnterParametersManually(!enterParametersManually);
                                    }}
                                />
                                <Form.Check
                                    type="checkbox"
                                    id="fromFile"
                                    label="Enter amount material, packaging and other selection - Indata from file"
                                    checked={enterParametersFromFile}
                                    onChange={() => {
                                        setEnterParametersFromFile(!enterParametersFromFile);
                                    }}
                                />
                            </Form.Group>
                            <Form.Group as={Row} className="d-flex mt-5" controlId="publicationDate" >
                                <Col sm="5">
                                    <Form.Label className="fw-bold h3">Download indata form</Form.Label>
                                    <Button variant="outline-dark border-0" onClick={handleFetchFromSimapro} >
                                        <i className="fs-3 bi-file-earmark-arrow-down"></i>
                                    </Button>
                                </Col>
                            </Form.Group>

                            {enterParametersFromFile
                                ? <Container>
                                    <Row >
                                        <Form.Label className="d-flex flex-column justify-content-center" as={Col} sm={2} >Indata from file</Form.Label>
                                        <Col sm="10">
                                            <Form.Control
                                                type="file"
                                                accept=".csv"
                                                ref={sidelistFileRef}
                                                onChange={handleSelectCsv}
                                                onClick={(event) => event.target.value = null}
                                                disabled={!itemsResult?.data}
                                            />
                                        </Col>
                                    </Row>
                                </Container>
                                : ""
                            }

                            <hr />
                            <div className="mb-5">
                                {parametersSimaproResult.isFetching ? (
                                    <div className="my-4"><Loading /></div>
                                ) :
                                    <Table variant="light" striped borderless>
                                        <thead>
                                            <tr key="bom-header">
                                                <th scope="col" className="col-2"> Material in product </th>
                                                <th scope="col" className="col-2"> Amount </th>
                                                <th scope="col" className="col-1"> Unit </th>
                                                <th scope="col" className="col-7"> Comment </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(parameters.filter(param => param.parameterTypeName === 'BOM').sort((a, b) => a.name.localeCompare(b.name)).map((parameter) => (
                                                <tr key={parameter.id} >
                                                    <td>{parameter.name}</td>
                                                    <td>
                                                        <Form.Control type="number"
                                                            min={0}
                                                            step='any'
                                                            value={parameter.calculatedValue}
                                                            onChange={(e) => handleParameterChange(parameter, e.target.value, "calculatedValue")}
                                                            disabled={!enterParametersManually}
                                                        />
                                                    </td>
                                                    <td>Kg</td>
                                                    <td>
                                                        <Form.Control type="text"
                                                            value={parameter.comment}
                                                            onChange={(e) => handleParameterChange(parameter, e.target.value, "comment")}
                                                            disabled={!enterParametersManually}
                                                        />
                                                    </td>
                                                </tr>
                                            )))}
                                        </tbody>
                                    </Table>
                                }
                            </div>
                            <div className="mb-5">
                                {parametersSimaproResult.isFetching ? (
                                    <div className="my-4"><Loading /></div>
                                ) :
                                    <Table variant="light" striped borderless>
                                        <thead>
                                            <tr key="pac-header">
                                                <th scope="col" className="col-2"> Packaging material </th>
                                                <th scope="col" className="col-2"> Amount </th>
                                                <th scope="col" className="col-1"> Unit </th>
                                                <th scope="col" className="col-7"> Comment </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(parameters.filter(param => param.parameterTypeName === 'PAC').sort((a, b) => a.name.localeCompare(b.name)).map((parameter) => (
                                                <tr key={parameter.id} >
                                                    <td>{parameter.name}</td>
                                                    <td><Form.Control type="number"
                                                        min={0}
                                                        value={parameter.calculatedValue}
                                                        onChange={(e) => handleParameterChange(parameter, e.target.value, "calculatedValue")}
                                                        disabled={!enterParametersManually}
                                                    /></td>
                                                    <td>Kg</td>
                                                    <td><Form.Control type="text"
                                                        value={parameter.comment}
                                                        onChange={(e) => handleParameterChange(parameter, e.target.value, "comment")}
                                                        disabled={!enterParametersManually}
                                                    />
                                                    </td>
                                                </tr>
                                            )))}
                                        </tbody>
                                    </Table>
                                }
                            </div>
                            <div className="mb-5">
                                {parametersSimaproResult.isFetching ? (
                                    <div className="my-4"><Loading /></div>
                                ) :
                                    <Table variant="light" striped borderless>
                                        <thead>
                                            <tr key="opt-header">
                                                <th scope="col" className="col-2"> Extra option </th>
                                                <th scope="col" className="col-2"> Amount </th>
                                                <th scope="col" className="col-1"> Unit </th>
                                                <th scope="col" className="col-7"> Comment </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {(parameters.filter(param => param.parameterTypeName === 'OPT').sort((a, b) => a.name.localeCompare(b.name)).map((parameter) => (
                                                <tr key={parameter.id} >
                                                    <td>{parameter.name.split('_').slice(0, -1).join('_')}</td>
                                                    <td><Form.Control type="number"
                                                        min={0}
                                                        value={parameter.calculatedValue}
                                                        onChange={(e) => handleParameterChange(parameter, e.target.value, "calculatedValue")}
                                                        disabled={!enterParametersManually}
                                                    /></td>
                                                    <td>{parameter.name.split('_').pop()}</td>
                                                    <td><Form.Control type="text"
                                                        value={parameter.comment}
                                                        onChange={(e) => handleParameterChange(parameter, e.target.value, "comment")}
                                                        disabled={!enterParametersManually}
                                                    />
                                                    </td>
                                                </tr>
                                            )))}
                                        </tbody>
                                    </Table>
                                }
                            </div>
                            <div className="mb-5">
                                {parametersSimaproResult.isFetching ? (
                                    <div className="my-4"><Loading /></div>
                                ) : (
                                    <Table variant="light" striped borderless>
                                        <thead>
                                            <tr key="ext-options-header">
                                                <th scope="col" className="col-6"> Selectable options </th>
                                                <th scope="col" className="col-6"> Comment </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                                {getGroupedExtParameters(parameters).map(group => (
                                                <tr key={group.key}>
                                                    <td>
                                                        <Form.Control
                                                            id="role"
                                                            as="select"
                                                            value={getExtGroupValue(group)}
                                                            onChange={(e) => handleExtSelectionChange(group.key, e.target.value)}
                                                            isInvalid={!checkIfExtSelected(group)}
                                                            disabled={!enterParametersManually}
                                                        >
                                                            <option value='DEFAULT' disabled>Choose parameter ...</option>
                                                            {group.value.map((val) => (
                                                                <option key={val.id} value={val.name}>{val.name}</option>
                                                            ))}
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">
                                                            Select parameter
                                                        </Form.Control.Feedback>
                                                    </td>
                                                    <td>
                                                        <Form.Control
                                                            type="text"
                                                            value={group.value.find(v => v.calculatedValue === 1) ? group.value.find(v => v.calculatedValue === 1).comment : group.value[0].comment}
                                                            onChange={(e) => handleGroupCommentChange(group, e.target.value)}
                                                            disabled={!enterParametersManually}
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                )}
                            </div>
                            <Form.Group className="rounded-pill w-100 border-2 mt-5 mb-5">
                                <Button variant="success" className="rounded-pill w-100 border-2" onClick={() => { handleGenerateEpdReport() }} disabled={generateSimaproReportMutation.isLoading}>
                                    {generateSimaproReportMutation.isLoading ?
                                        <div>
                                            <Spinner size="sm">
                                            </Spinner>
                                            <span>
                                                {' '}Generating report...
                                            </span>
                                        </div>
                                        : <div>
                                            Calculate and download files
                                        </div>}
                                </Button>
                                {user.isAdmin
                                    ?
                                    <Button variant="success" className="rounded-pill w-100 border-2 mt-3" onClick={() => { handleValidateParameters() }} disabled={!parametersSimaproResult?.data}>
                                        Validate compliance with Simapro
                                    </Button>
                                    : ""
                                }
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </fieldset>
            <div>
                <ClimateChangeChart title="Climate change - Fossil" lable="kg CO2 ekv. GWP fossil" data={generateSimaproReportMutation?.data?.data?.climateImpacts ? generateSimaproReportMutation.data.data.climateImpacts.filter(data => data.impactCategory === 'Climate change - Fossil') : null} chartRef={climateChangeFossilChartRef} ></ClimateChangeChart>
            </div>
            <div>
                <ClimateChangeChart title="Climate change - Biogenic" lable="kg CO2 ekv. GWP biogenic" data={generateSimaproReportMutation?.data?.data?.climateImpacts ? generateSimaproReportMutation.data.data.climateImpacts.filter(data => data.impactCategory === 'Climate change - Biogenic') : null} chartRef={climateChangeBiogenicChartRef} ></ClimateChangeChart>
            </div>
            <div>
                <ClimateChangeChart title="Climate change - Total" lable="kg CO2 ekv. GWP tot" data={generateSimaproReportMutation?.data?.data?.climateImpacts ? generateSimaproReportMutation.data.data.climateImpacts.filter(data => data.impactCategory === 'Climate change') : null} chartRef={climateChangeTotalChartRef} ></ClimateChangeChart>
            </div>
        </Container>
    );
}