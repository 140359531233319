import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import logo from '../resources/Tyrens_logo.png'
import arial from '../resources/fonts/arial/ARIAL.TTF'
import arialbd from '../resources/fonts/arial/ARIALBD.TTF'
import ariali from '../resources/fonts/arial/ARIALI.TTF'

Font.register({ family: 'Arial', src: arial });
Font.register({ family: 'Arial Bold', src: arialbd });
Font.register({ family: 'Arial Italic', src: ariali });

// Create styles
export const ReportStyles = StyleSheet.create({
    page: {
        backgroundColor: "white",
        color: "black",
        paddingTop: 20,
        paddingLeft: 50,
        paddingRight: 50,
        paddingBottom: 75,
    },
    section: {
        margin: 10,
        padding: 10,
    },
    logo: {
        width: 108,
        height: 24,
        marginTop: 10,
        marginBottom: 20,
    },
    header1: {
        fontSize: 20,
        fontFamily: "Arial Bold",
        color: "#f3755d",
        marginTop: 10,
    },
    header2: {
        fontSize: 20,
        fontFamily: "Arial",
        color: "#f3755d",

    },
    header3: {
        fontSize: 11,
        fontFamily: "Arial Bold",
        color: "#f3755d",
        marginTop: 10,
    },
    header4: {
        fontSize: 11,
        fontFamily: "Arial",
        color: "#f3755d",
        marginTop: 10
    },
    header5: {
        fontSize: 11,
        fontFamily: "Arial",
        color: "#ed9b33",
        marginTop: 10
    },
    header5WithoutMargin: {
        fontSize: 11,
        fontFamily: "Arial",
        color: "#ed9b33",
    },
    text: {
        fontSize: 11,
        fontFamily: "Arial",
        marginTop: 10,
    },
    pageNumber: {
        fontSize: 8.5,
        fontFamily: "Arial",
        marginTop: 10,
        position: "absolute",
        top: "8px",
        right: "16px",

    },
    footer: {
        position: 'absolute',
        fontSize: 8,
        fontFamily: "Arial",
        marginTop: 10,
        bottom: 30,
        marginLeft: 100,
        width: 400,
    },
    line: {
        border: '1px solid black',
        borderRadius: '1px',
    },
    table: {
        width: "100%",
        border: "0.5pt solid black",
        borderBottom: false,
        borderLeft: false,
    },
    cell: {
        flex: 1,
        justifyContent: "stretch",
        textAlign: "left",
        fontSize: 8,
        fontFamily: "Arial",
        paddingLeft: 5,
        paddingTop: 2,
        paddingBottom: 2,
        borderRight: false,
        borderLeft: "0.5pt solid black",
        wordWrap: "break-word",
        whiteSpace: "pre-wrap"
    },
    cellExtraSmall: {
        flex: 0.1,
        justifyContent: "stretch",
        textAlign: "left",
        fontSize: 8,
        fontFamily: "Arial",
        paddingLeft: 5,
        paddingTop: 2,
        paddingBottom: 2,
        borderRight: false,
        borderLeft: "0.5pt solid black",
        wordWrap: "break-word",
        whiteSpace: "pre-wrap"
    },
    cellSmall: {
        flex: 0.3,
        justifyContent: "stretch",
        textAlign: "left",
        fontSize: 8,
        fontFamily: "Arial",
        paddingLeft: 5,
        paddingTop: 2,
        paddingBottom: 2,
        borderRight: false,
        borderLeft: "0.5pt solid black",
        wordWrap: "break-word",
        whiteSpace: "pre-wrap"
    },
    cellMedium: {
        flex: 0.6,
        justifyContent: "stretch",
        textAlign: "left",
        fontSize: 8,
        fontFamily: "Arial",
        paddingLeft: 5,
        paddingTop: 2,
        paddingBottom: 2,
        borderRight: false,
        borderLeft: "0.5pt solid black",
        wordWrap: "break-word",
        whiteSpace: "pre-wrap"
    },
    cellBig: {
        flex: 1,
        justifyContent: "stretch",
        textAlign: "left",
        fontSize: 8,
        fontFamily: "Arial",
        paddingLeft: 5,
        paddingTop: 2,
        paddingBottom: 2,
        borderRight: false,
        borderLeft: "0.5pt solid black",
        wordWrap: "break-word",
        whiteSpace: "pre-wrap"
    },
    cellHeader: {
        flex: 1,
        justifyContent: "stretch",
        textAlign: "left",
        fontSize: 8,
        fontFamily: "Arial Bold",
        paddingLeft: 5,
        paddingTop: 2,
        paddingBottom: 2,
        borderRight: false,
        borderLeft: "0.5pt solid black",
        wordWrap: "break-word",
        whiteSpace: "pre-wrap"
    },
    cellHeaderExtraSmall: {
        flex: 0.1,
        justifyContent: "stretch",
        textAlign: "left",
        fontSize: 8,
        fontFamily: "Arial Bold",
        paddingLeft: 5,
        paddingTop: 2,
        paddingBottom: 2,
        borderRight: false,
        borderLeft: "0.5pt solid black",
        wordWrap: "break-word",
        whiteSpace: "pre-wrap"
    },
    cellHeaderSmall: {
        flex: 0.3,
        justifyContent: "stretch",
        textAlign: "left",
        fontSize: 8,
        fontFamily: "Arial Bold",
        paddingLeft: 5,
        paddingTop: 2,
        paddingBottom: 2,
        borderRight: false,
        borderLeft: "0.5pt solid black",
        wordWrap: "break-word",
        whiteSpace: "pre-wrap"
    },
    cellHeaderMedium: {
        flex: 0.6,
        justifyContent: "stretch",
        textAlign: "left",
        fontSize: 8,
        fontFamily: "Arial Bold",
        paddingLeft: 5,
        paddingTop: 2,
        paddingBottom: 2,
        borderRight: false,
        borderLeft: "0.5pt solid black",
        wordWrap: "break-word",
        whiteSpace: "pre-wrap"
    },
    cellHeaderBig: {
        flex: 1,
        justifyContent: "stretch",
        textAlign: "left",
        fontSize: 8,
        fontFamily: "Arial Bold",
        paddingLeft: 5,
        paddingTop: 2,
        paddingBottom: 2,
        borderRight: false,
        borderLeft: "0.5pt solid black",
        wordWrap: "break-word",
        whiteSpace: "pre-wrap"
    },
    row: {
        borderBottom: "0.5pt solid black",
        borderRight: false,
        borderLeft: false,
        borderTop: false,
        width: "100%",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    breakMedium:
    {
        marginTop: 10
    },
    image: {
        width: 400,
    },
});

export const CalculateClimateImpactSum = (climateImpactData) => {
    let total = 0;
    climateImpactData.forEach((parameter) => {
        total += parseFloat(parameter.value);
    });
    return total;
}

const EnvironmentalIndicatorColumns = ["A1-A3", "A4", "A5", "C1", "C2", "C3", "C4", "D"];

const EnvironmentalIndicatorRows = [
    {
        name: "Particulate matter",
        category: "Particulate Matter emissions",
        unit: "Disease incidence",
    },
    {
        name: "Ionising radiation",
        category: "Ionizing radiation, human health",
        unit: "kBq U235 eq.",
    },
    {
        name: "Ecotoxicity, freshwater",
        category: "Eco - toxicity(freshwater)",
        unit: "CTUe",
    },
    {
        name: "Human toxicity, cancer",
        category: "Human toxicity, cancer",
        unit: "CTUh",
    },
    {
        name: "Human toxicity, non-cancer",
        category: "Human toxicity, non-cancer effects",
        unit: "CTUh",
    },
    {
        name: "Land use",
        category: "Land use related impacts/ Soil quality",
        unit: "dimensionless",
    },
]

export const ClimateImpactReport = (reportId, productData, climateImpactData, environmentImpactIndicators, climateChangeFossilChart, climateChangeBiogenicChart, climateChangeTotalChart) => (
    <Document title={`test_report`} >
        <Page size="A4" style={ReportStyles.page}>
            <View style={ReportStyles.section} fixed>
                <Image style={ReportStyles.logo} src={logo} />
                <Text style={ReportStyles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber}(${totalPages})`)} />
            </View>

            <View style={ReportStyles.section}>
                <Text style={ReportStyles.header2}>CLIMATE IMPACT REPORT</Text>
                <Text style={ReportStyles.breakMedium} />
                <View style={ReportStyles.table}>
                    <View style={ReportStyles.row}>
                        <View style={ReportStyles.cellHeaderMedium} wrap={true}>
                            <Text>Product name</Text>
                        </View>
                        <View style={ReportStyles.cellBig} wrap={true}>
                            <Text>{productData.name}</Text>
                        </View>
                    </View>
                    <View style={ReportStyles.row}>
                        <View style={ReportStyles.cellHeaderMedium} wrap={true}>
                            <Text>Product identification</Text>
                        </View>
                        <View style={ReportStyles.cellBig} wrap={true}>
                            <Text>{productData.identification}</Text>
                        </View>
                    </View>
                    <View style={ReportStyles.row}>
                        <View style={ReportStyles.cellHeaderMedium} wrap={true}>
                            <Text>Product description</Text>
                        </View>
                        <View style={ReportStyles.cellBig} wrap={true}>
                            <Text>{productData.description}</Text>
                        </View>
                    </View>
                    <View style={ReportStyles.row}>
                        <View style={ReportStyles.cellHeaderMedium} wrap={true}>
                            <Text>UN CPC Code</Text>
                        </View>
                        <View style={ReportStyles.cellBig} wrap={true}>
                            <Text>{productData.unCpcCode}</Text>
                        </View>
                    </View>
                    <View style={ReportStyles.row}>
                        <View style={ReportStyles.cellHeaderMedium} wrap={true}>
                            <Text>EPD registration number</Text>
                        </View>
                        <View style={ReportStyles.cellBig} wrap={true}>
                            <Text>{productData.epdRegistrationNumber}</Text>
                        </View>
                    </View>
                    <View style={ReportStyles.row}>
                        <View style={ReportStyles.cellHeaderMedium} wrap={true}>
                            <Text>Publication date</Text>
                        </View>
                        <View style={ReportStyles.cellBig} wrap={true}>
                            <Text>{productData.publicationDate}</Text>
                        </View>
                    </View>
                    <View style={ReportStyles.row}>
                        <View style={ReportStyles.cellHeaderMedium} wrap={true}>
                            <Text>ID</Text>
                        </View>
                        <View style={ReportStyles.cellBig} wrap={true}>
                            <Text>{reportId}</Text>
                        </View>
                    </View>
                </View>
                <Text style={ReportStyles.breakMedium} />
                <View>
                    <Text style={ReportStyles.header5}>Climate change - Fossil</Text>
                    <View style={ReportStyles.table}>
                        <View style={ReportStyles.row}>
                            <View style={ReportStyles.cellHeaderBig} wrap={true}>
                                <Text>Product</Text>
                            </View>
                            <View style={ReportStyles.cellHeaderSmall} wrap={true}>
                                <Text>Unit</Text>
                            </View>
                            <View style={ReportStyles.cellHeaderSmall} wrap={true}>
                                <Text>Value</Text>
                            </View>
                        </View>
                        {
                            (climateImpactData.filter(data => data.impactCategory === 'Climate change - Fossil').map((parameter) => {
                                return (
                                    <>
                                        <View style={ReportStyles.row}>
                                            <View style={ReportStyles.cellBig} wrap={true}>
                                                <Text>{parameter.product}</Text>
                                            </View>
                                            <View style={ReportStyles.cellSmall} wrap={true}>
                                                <Text>{parameter.unit}</Text>
                                            </View>
                                            <View style={ReportStyles.cellSmall} wrap={true}>
                                                <Text>{parameter.value}</Text>
                                            </View>
                                        </View>
                                    </>
                                )
                            }))
                        }
                        <View style={ReportStyles.row}>
                            <View style={ReportStyles.cellHeaderBig} wrap={true}>
                                <Text>GWP tot SUM A1-A3</Text>
                            </View>
                            <View style={ReportStyles.cellHeaderSmall} wrap={true}>
                                <Text>kg CO2 eq</Text>
                            </View>
                            <View style={ReportStyles.cellHeaderSmall} wrap={true}>
                                <Text>{CalculateClimateImpactSum(climateImpactData.filter(data => data.impactCategory === 'Climate change - Fossil')).toFixed(3)}</Text>
                            </View>
                        </View>
                    </View>
                    <View wrap={false}>
                        <Image style={ReportStyles.image} src={climateChangeFossilChart} />
                    </View>
                </View>
                <View break>
                    <Text style={ReportStyles.header5}>Climate change - Biogenic</Text>
                    <View style={ReportStyles.table}>
                        <View style={ReportStyles.row}>
                            <View style={ReportStyles.cellHeaderBig} wrap={true}>
                                <Text>Product</Text>
                            </View>
                            <View style={ReportStyles.cellHeaderSmall} wrap={true}>
                                <Text>Unit</Text>
                            </View>
                            <View style={ReportStyles.cellHeaderSmall} wrap={true}>
                                <Text>Value</Text>
                            </View>
                        </View>
                        {
                            (climateImpactData.filter(data => data.impactCategory === 'Climate change - Biogenic').map((parameter) => {
                                return (
                                    <>
                                        <View style={ReportStyles.row}>
                                            <View style={ReportStyles.cellBig} wrap={true}>
                                                <Text>{parameter.product}</Text>
                                            </View>
                                            <View style={ReportStyles.cellSmall} wrap={true}>
                                                <Text>{parameter.unit}</Text>
                                            </View>
                                            <View style={ReportStyles.cellSmall} wrap={true}>
                                                <Text>{parameter.value}</Text>
                                            </View>
                                        </View>
                                    </>
                                )
                            }))
                        }
                        <View style={ReportStyles.row}>
                            <View style={ReportStyles.cellHeaderBig} wrap={true}>
                                <Text>GWP tot SUM A1-A3</Text>
                            </View>
                            <View style={ReportStyles.cellHeaderSmall} wrap={true}>
                                <Text>kg CO2 eq</Text>
                            </View>
                            <View style={ReportStyles.cellHeaderSmall} wrap={true}>
                                <Text>{CalculateClimateImpactSum(climateImpactData.filter(data => data.impactCategory === 'Climate change - Biogenic')).toFixed(3)}</Text>
                            </View>
                        </View>
                    </View>
                    <View wrap={false}>
                        <Image style={ReportStyles.image} src={climateChangeBiogenicChart} />
                    </View>
                </View>
                <View break>
                    <Text style={ReportStyles.header5}>Climate change - Total</Text>
                    <View style={ReportStyles.table}>
                        <View style={ReportStyles.row}>
                            <View style={ReportStyles.cellHeaderBig} wrap={true}>
                                <Text>Product</Text>
                            </View>
                            <View style={ReportStyles.cellHeaderSmall} wrap={true}>
                                <Text>Unit</Text>
                            </View>
                            <View style={ReportStyles.cellHeaderSmall} wrap={true}>
                                <Text>Value</Text>
                            </View>
                        </View>
                        {
                            (climateImpactData.filter(data => data.impactCategory === 'Climate change').map((parameter) => {
                                return (
                                    <>
                                        <View style={ReportStyles.row}>
                                            <View style={ReportStyles.cellBig} wrap={true}>
                                                <Text>{parameter.product}</Text>
                                            </View>
                                            <View style={ReportStyles.cellSmall} wrap={true}>
                                                <Text>{parameter.unit}</Text>
                                            </View>
                                            <View style={ReportStyles.cellSmall} wrap={true}>
                                                <Text>{parameter.value}</Text>
                                            </View>
                                        </View>
                                    </>
                                )
                            }))
                        }
                        <View style={ReportStyles.row}>
                            <View style={ReportStyles.cellHeaderBig} wrap={true}>
                                <Text>GWP tot SUM A1-A3</Text>
                            </View>
                            <View style={ReportStyles.cellHeaderSmall} wrap={true}>
                                <Text>kg CO2 eq</Text>
                            </View>
                            <View style={ReportStyles.cellHeaderSmall} wrap={true}>
                                <Text>{CalculateClimateImpactSum(climateImpactData.filter(data => data.impactCategory === 'Climate change')).toFixed(3)}</Text>
                            </View>
                        </View>
                    </View>
                    <View wrap={false}>
                        <Image style={ReportStyles.image} src={climateChangeTotalChart} />
                    </View>
                </View>

                <View break>
                    <Text style={ReportStyles.header5}>Additional environmental impact indicators</Text>
                    <View style={ReportStyles.table}>
                        <View style={ReportStyles.row}>
                            <View style={ReportStyles.cellHeaderMedium} wrap={true}>
                                <Text>Impact category</Text>
                            </View>
                            <View style={ReportStyles.cellHeaderMedium} wrap={true}>
                                <Text>Unit</Text>
                            </View>
                            {EnvironmentalIndicatorColumns.map((colName) => {
                                return (
                                    <View style={ReportStyles.cellHeaderSmall} wrap={true}>
                                        <Text>{colName}</Text>
                                    </View>
                                )
                            })}
                        </View>
                        {EnvironmentalIndicatorRows.map((row) => {
                            return (
                                <View style={ReportStyles.row}>
                                    <View style={ReportStyles.cellMedium} wrap={true}>
                                        <Text>{row.category}</Text>
                                    </View>
                                    <View style={ReportStyles.cellMedium} wrap={true}>
                                        <Text>{row.unit}</Text>
                                    </View>
                                    {
                                        environmentImpactIndicators.find(data => data.indicator === row.name)
                                            ? (Object.entries(environmentImpactIndicators.find(data => data.indicator === row.name)?.values)?.map(([, value]) => {
                                                return (
                                                    <View style={ReportStyles.cellSmall} wrap={true}>
                                                        <Text>{value}</Text>
                                                    </View>
                                                )
                                            }))
                                            : EnvironmentalIndicatorColumns.map(() => {
                                                return (
                                                    <View style={ReportStyles.cellHeaderSmall} wrap={true}>
                                                        <Text>-</Text>
                                                    </View>
                                                )
                                            })
                                    }
                                </View>
                            )
                        })}
                    </View>
                </View>
            </View>
            <View style={ReportStyles.footer} fixed>
                <hr style={ReportStyles.line} />
            </View>
        </Page>
    </Document>
);

export const InputDataReport = (reportId, productData, inputParameters, extParameters) => (
    <Document title={`test_report`} >
        <Page size="A4" style={ReportStyles.page}>
            <View style={ReportStyles.section} fixed>
                <Image style={ReportStyles.logo} src={logo} />
                <Text style={ReportStyles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber}(${totalPages})`)} />
            </View>

            <View style={ReportStyles.section}>
                <Text style={ReportStyles.header2}>INPUT DATA REPORT</Text>
                <Text style={ReportStyles.text}></Text>

                <View style={ReportStyles.table}>
                    <View style={ReportStyles.row}>
                        <View style={ReportStyles.cellHeaderMedium} wrap={true}>
                            <Text>Product name</Text>
                        </View>
                        <View style={ReportStyles.cellBig} wrap={true}>
                            <Text>{productData.name}</Text>
                        </View>
                    </View>
                    <View style={ReportStyles.row}>
                        <View style={ReportStyles.cellHeaderMedium} wrap={true}>
                            <Text>Product identification</Text>
                        </View>
                        <View style={ReportStyles.cellBig} wrap={true}>
                            <Text>{productData.identification}</Text>
                        </View>
                    </View>
                    <View style={ReportStyles.row}>
                        <View style={ReportStyles.cellHeaderMedium} wrap={true}>
                            <Text>Product description</Text>
                        </View>
                        <View style={ReportStyles.cellBig} wrap={true}>
                            <Text>{productData.description}</Text>
                        </View>
                    </View>
                    <View style={ReportStyles.row}>
                        <View style={ReportStyles.cellHeaderMedium} wrap={true}>
                            <Text>UN CPC Code</Text>
                        </View>
                        <View style={ReportStyles.cellBig} wrap={true}>
                            <Text>{productData.unCpcCode}</Text>
                        </View>
                    </View>
                    <View style={ReportStyles.row}>
                        <View style={ReportStyles.cellHeaderMedium} wrap={true}>
                            <Text>EPD registration number</Text>
                        </View>
                        <View style={ReportStyles.cellBig} wrap={true}>
                            <Text>{productData.epdRegistrationNumber}</Text>
                        </View>
                    </View>
                    <View style={ReportStyles.row}>
                        <View style={ReportStyles.cellHeaderMedium} wrap={true}>
                            <Text>Publication date</Text>
                        </View>
                        <View style={ReportStyles.cellBig} wrap={true}>
                            <Text>{productData.publicationDate}</Text>
                        </View>
                    </View>
                    <View style={ReportStyles.row}>
                        <View style={ReportStyles.cellHeaderMedium} wrap={true}>
                            <Text>ID</Text>
                        </View>
                        <View style={ReportStyles.cellBig} wrap={true}>
                            <Text>{reportId}</Text>
                        </View>
                    </View>
                </View>
                <Text style={ReportStyles.breakMedium} />

                <View style={ReportStyles.table}>
                    <View style={ReportStyles.row}>
                        <View style={ReportStyles.cellHeaderBig} wrap={true}>
                            <Text>Bom - Material</Text>
                        </View>
                        <View style={ReportStyles.cellHeaderSmall} wrap={true}>
                            <Text>Amount</Text>
                        </View>
                        <View style={ReportStyles.cellHeaderExtraSmall} wrap={true}>
                            <Text>Unit</Text>
                        </View>
                        <View style={ReportStyles.cellHeaderMedium} wrap={true}>
                            <Text>Comment</Text>
                        </View>
                    </View>
                    {
                        (inputParameters.filter(param => param.parameterTypeName === 'BOM').map((parameter) => {
                            return (
                                <>
                                    <View style={ReportStyles.row}>
                                        <View style={ReportStyles.cellBig} wrap={true}>
                                            <Text wrap={true}>{parameter.name}</Text>
                                        </View>
                                        <View style={ReportStyles.cellSmall} wrap={true}>
                                            <Text>{parameter.calculatedValue.toFixed(6)}</Text>
                                        </View>
                                        <View style={ReportStyles.cellExtraSmall} wrap={true}>
                                            <Text>Kg</Text>
                                        </View>
                                        <View style={ReportStyles.cellMedium} wrap={true}>
                                            <Text>{parameter.comment}</Text>
                                        </View>
                                    </View>
                                </>
                            )
                        }))
                    }
                </View>
                <Text style={ReportStyles.breakMedium} />
                <View style={ReportStyles.table}>
                    <View style={ReportStyles.row}>
                        <View style={ReportStyles.cellHeaderBig} wrap={true}>
                            <Text>Packaging-Material</Text>
                        </View>
                        <View style={ReportStyles.cellHeaderSmall} wrap={true}>
                            <Text>Amount</Text>
                        </View>
                        <View style={ReportStyles.cellHeaderExtraSmall} wrap={true}>
                            <Text>Unit</Text>
                        </View>
                        <View style={ReportStyles.cellHeaderMedium} wrap={true}>
                            <Text>Comment</Text>
                        </View>
                    </View>
                    {
                        (inputParameters.filter(param => param.parameterTypeName === 'PAC').map((parameter) => {
                            return (
                                <>
                                    <View style={ReportStyles.row}>
                                        <View style={ReportStyles.cellBig} wrap={true}>
                                            <Text>{parameter.name}</Text>
                                        </View>
                                        <View style={ReportStyles.cellSmall} wrap={true}>
                                            <Text>{parameter.calculatedValue.toFixed(6)}</Text>
                                        </View>
                                        <View style={ReportStyles.cellExtraSmall} wrap={true}>
                                            <Text>Kg</Text>
                                        </View>
                                        <View style={ReportStyles.cellMedium} wrap={true}>
                                            <Text>{parameter.comment}</Text>
                                        </View>
                                    </View>
                                </>
                            )
                        }))
                    }
                </View>
                <Text style={ReportStyles.breakMedium} />
                <View style={ReportStyles.table}>
                    <View style={ReportStyles.row}>
                        <View style={ReportStyles.cellHeaderBig} wrap={true}>
                            <Text>Extra-Option</Text>
                        </View>
                        <View style={ReportStyles.cellHeaderSmall} wrap={true}>
                            <Text>Value</Text>
                        </View>
                        <View style={ReportStyles.cellHeaderExtraSmall} wrap={true}>
                            <Text>Unit</Text>
                        </View>
                        <View style={ReportStyles.cellHeaderMedium} wrap={true}>
                            <Text>Comment</Text>
                        </View>
                    </View>
                    {
                        (inputParameters.filter(param => param.parameterTypeName === 'OPT').map((parameter) => {
                            return (
                                <>
                                    <View style={ReportStyles.row}>
                                        <View style={ReportStyles.cellBig} wrap={true}>
                                            <Text>{parameter.name.split('_').slice(0, -1).join('_')}</Text>
                                        </View>
                                        <View style={ReportStyles.cellSmall} wrap={true}>
                                            <Text>{parameter.calculatedValue.toFixed(6)}</Text>
                                        </View>
                                        <View style={ReportStyles.cellExtraSmall} wrap={true}>
                                            <Text>{parameter.name.split('_').pop()}</Text>
                                        </View>
                                        <View style={ReportStyles.cellMedium} wrap={true}>
                                            <Text>{parameter.comment}</Text>
                                        </View>
                                    </View>
                                </>
                            )
                        }))
                    }
                </View>
                <Text style={ReportStyles.breakMedium} />
                <View style={ReportStyles.table}>
                    <View style={ReportStyles.row}>
                        <View style={ReportStyles.cellHeader} wrap={true}>
                            <Text>Extra variants</Text>
                        </View>
                        <View style={ReportStyles.cellHeader} wrap={true}>
                            <Text>Comment</Text>
                        </View>
                    </View>
                    {
                        (extParameters.map((group) => {
                            return (
                                <>
                                    <View style={ReportStyles.row}>
                                        <View style={ReportStyles.cell} wrap={true}>
                                            <Text>{group.value.find(v => v.calculatedValue === 1) ? group.value.find(v => v.calculatedValue === 1).name : group.value[0].name}</Text>
                                        </View>
                                        <View style={ReportStyles.cell} wrap={true}>
                                            <Text>{group.value.find(v => v.calculatedValue === 1) ? group.value.find(v => v.calculatedValue === 1).comment : group.value[0].comment}</Text>
                                        </View>
                                    </View>
                                </>
                            )
                        }))
                    }
                </View>
                <Text style={ReportStyles.breakMedium} />
            </View>
            <View style={ReportStyles.footer} fixed>
                <hr style={ReportStyles.line} />
            </View>
        </Page>
    </Document>
);