import 'bootstrap/dist/css/bootstrap.css';
import "bootstrap-icons/font/bootstrap-icons.css";
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './identity/authProvider';
import { GlobalProvider } from './components/contexts/GlobalContext'
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { NotifierProvider } from '@de/react-bootstrap-notifier';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { createBrowserHistory } from 'history';
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom"

const browserHistory = createBrowserHistory();
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
    config: {
        connectionString: process.env.REACT_APP_INSTRUMENTATION_CONNECTION_STRING,
        enableAutoRouteTracking: true,
        disableAjaxTracking: false,
        autoTrackPageVisitTime: true,
        enableCorsCorrelation: true,
        enableRequestHeaderTracking: true,
        enableResponseHeaderTracking: true,
        extensions: [reactPlugin],
        extensionConfig: {
            [reactPlugin.identifier]: { history: browserHistory }
        }
    }
});

appInsights.loadAppInsights();

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();

if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback(event => {
    if ((event.eventType === EventType.SSO_SILENT_SUCCESS || event.eventType === EventType.LOGIN_SUCCESS) && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
    }
});

function ErrorFallback() {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/');
        window.location.reload();
    };

    return (
        <div className="d-flex flex-column align-items-center m-5">
            <Form.Label className="h3">Something went wrong.</Form.Label>
            <Button
                id="home"
                variant="link"
                onClick={handleClick}
            >
                back to home
            </Button>
        </div>
    );
}

function ErrorBoundary({ children }) {
    return (
        <ReactErrorBoundary
            FallbackComponent={ErrorFallback}
            onError={(error, errorInfo) => {
                reactPlugin.trackException({ error, properties: { errorInfo } });
            }}
        >
            {children}
        </ReactErrorBoundary>
    );
}

root.render(
    <BrowserRouter basename={baseUrl}>
        <ErrorBoundary>
            <GlobalProvider>
                <NotifierProvider>
                    <MsalProvider instance={msalInstance}>
                        <App />
                    </MsalProvider>
                </NotifierProvider>
            </GlobalProvider >
        </ErrorBoundary>
    </BrowserRouter>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();