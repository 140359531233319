import React, { useContext } from 'react';
import { Nav, Navbar, Container, Tooltip, OverlayTrigger } from 'react-bootstrap';
import logo from '../resources/Tyrens_logo.png'
import './css/TopBar.css';
import { GlobalContext } from './contexts/GlobalContext';
import { Link, useLocation } from "react-router-dom"
import { Notifier } from '@de/react-bootstrap-notifier';

export function TopBar() {
    const location = useLocation();
    const { user, currentProject } = useContext(GlobalContext)

    return (
        <div>
            <Navbar>
                <Container fluid>
                    <Nav>
                        <Navbar.Brand className="d-none d-md-block">
                            <img src={logo} alt="Tyrens Logo" className="tyrens-logo align-middle" />
                        </Navbar.Brand>
                        <Navbar.Brand className="d-none d-sm-block">
                            <span className="fs-6 align-middle" style={{ color: "gray" }}>BETA</span>
                        </Navbar.Brand>
                        <Navbar.Brand className="d-none d-sm-block">
                            <span className="fs-4 fw-bolder align-middle" style={{ color: "rgb(25, 135, 84)" }}>EPD-GENERATOR</span>
                        </Navbar.Brand>

                        <div className="hstack">
                            <Nav.Item>
                                <OverlayTrigger
                                    placement="bottom"
                                    overlay={<Tooltip id={`tooltip-1`}>Home page</Tooltip>}
                                >
                                    <Nav.Link
                                        id="home"
                                        as={Link}
                                        to=""
                                        className="nav-link"
                                        style={{ color: "black" }}
                                    >
                                        {location.pathname === '/' ? (
                                            <i className="fs-4 bi-house-fill nav-link-text"></i>
                                        ) : (<i className="fs-4 bi-house nav-link-text text-secondary"></i>)}
                                    </Nav.Link>
                                </OverlayTrigger>
                            </Nav.Item>
                            {!!currentProject.name &&
                                <Nav.Item>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id={`tooltip-1`}>Input form</Tooltip>}
                                    >
                                        <Nav.Link
                                            id="inputForm"
                                            as={Link}
                                            to="/inputForm"
                                            className="nav-link"
                                            style={{ color: "black" }}
                                        >
                                            {location.pathname === '/inputForm'
                                                ? (<i className="fs-4 bi-calculator-fill nav-link-text"></i>)
                                                : (<i className="fs-4 bi-calculator nav-link-text text-secondary"></i>)}
                                        </Nav.Link>
                                    </OverlayTrigger>
                                </Nav.Item>}
                            {!!currentProject.name && (user?.role === 'Admin' || user?.isAdmin) &&
                                <Nav.Item>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id={`tooltip-2`}>Project configuration</Tooltip>}
                                    >
                                        <Nav.Link
                                            id="templateManagement"
                                            as={Link}
                                            to="/templateManagement"
                                            className="nav-link"
                                            style={{ color: "black" }}
                                        >
                                            {location.pathname === '/templateManagement'
                                                ? (<i className="fs-4 bi-gear-fill nav-link-text"></i>)
                                                : (<i className="fs-4 bi-gear nav-link-text text-secondary"></i>)}
                                        </Nav.Link>
                                    </OverlayTrigger>
                                </Nav.Item>}
                            {user?.isAdmin &&
                                <Nav.Item>
                                    <OverlayTrigger
                                        placement="bottom"
                                        overlay={<Tooltip id={`tooltip-3`}>User configuration</Tooltip>}
                                    >
                                        <Nav.Link
                                            id="userConfiguration"
                                            as={Link}
                                            to="/userConfiguration"
                                            className="nav-link"
                                            style={{ color: "black" }}
                                        >
                                            {location.pathname === '/userConfiguration'
                                                ? (<i className="fs-4 bi-people-fill nav-link-text"></i>)
                                                : (<i className="fs-4 bi-people nav-link-text text-secondary"></i>)}
                                        </Nav.Link>
                                    </OverlayTrigger>
                                </Nav.Item>}
                        </div>
                    </Nav>

                    {user.id > 0 ?
                        <Nav className="justify-content-end">
                            <Nav.Item className="mx-4 text-nowrap vstack">
                                <div className="text-center fs-6">project</div>
                                <div className="text-center fw-semibold fs-5">{currentProject.name}</div>
                            </Nav.Item>
                            <Nav.Item className="text-nowrap me-3">
                                <Notifier></Notifier>
                            </Nav.Item>
                        </Nav>
                        : null}
                </Container>
            </Navbar>
            <hr className="ruler" />
        </div>
    );
}