import { Home } from "./components/Home";
import { InputForm } from "./components/InputForm";
import { ProjectConfiguration } from "./components/ProjectConfiguration";
import { UserConfiguration } from "./components/UserConfiguration";

const AppRoutes = [
    {
        index: true,
        element: <Home />
    },
    {
        path: '/inputForm',
        element: <InputForm />
    },
    {
        path: '/templateManagement',
        element: <ProjectConfiguration />
    },
    {
        path: '/userConfiguration',
        element: <UserConfiguration />
    }
];

export default AppRoutes;
