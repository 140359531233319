/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom"
import React, { useContext, useRef, useEffect, useState } from 'react';
import { GlobalContext } from './contexts/GlobalContext';
import { uploadReportTemplate, fetchReportTemplates, deleteReportTemplate, updateItems, fetchSimaproParameters, fetchItems, fetchReportTemplate, fetchMasterTemplateNames, updateProject } from '../Queries'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Form, Col, Row, Button, Spinner, Container, Table } from "react-bootstrap";
import Papa from 'papaparse'
import { Loading } from './Loading';
import { useNotifications } from '@de/react-bootstrap-notifier';
import fileDownload from 'js-file-download'

export function ProjectConfiguration() {
    const navigate = useNavigate()

    const { currentProject, setCurrentProject, parameterParsingRegex } = useContext(GlobalContext);
    const { addInfo, addWarning, addError } = useNotifications();
    const [itemsMapping, setItemsMapping] = useState(null);
    const [fileIsValid, setFileIsValid] = useState(false);
    const templateFileToUpload = useRef(null);
    const sidelistMapperFileRef = useRef(null)
    const [selectedReportTemplate, setSelectedReportTemplate] = useState(null);

    const queryClient = useQueryClient()
    const reportTemplatesResult = useQuery({ queryKey: ['fetchReportTemplates', currentProject.id], queryFn: () => fetchReportTemplates(currentProject.id) })
    const parametersSimaproResult = useQuery({ queryKey: ['fetchSimaproParameters', currentProject.id], queryFn: () => fetchSimaproParameters(currentProject.id), enabled: false })
    const itemsResult = useQuery({ queryKey: ['fetchItems', currentProject.id], queryFn: () => fetchItems(currentProject.id), enabled: !!currentProject?.id })
    const masterTemplateNamesResult = useQuery({ queryKey: ['fetchMasterTemplateNames'], queryFn: () => fetchMasterTemplateNames() })

    const reportTemplateResult = useQuery({
        queryKey: ['fetchReportTemplate', selectedReportTemplate],
        queryFn: async () => {
            try {
                const blob = await fetchReportTemplate(selectedReportTemplate, currentProject.id);
                fileDownload(blob, selectedReportTemplate.replace(`${currentProject.id}_`, ""));
            } catch (error) {
                addError({ ...error.response.data, message: `An error occurred while downloading the file: "${selectedReportTemplate}"!` })
            }
        },
        enabled: false
    })

    const uploadReportTemplateResult = useMutation({
        mutationFn: async (formData) => await uploadReportTemplate(formData, currentProject.id),
        onSuccess: () => {
            addInfo("Template uploaded");
            queryClient.invalidateQueries({ queryKey: ['fetchReportTemplates', currentProject.id] })
        },
        onError: (error) => addError({ ...error.response.data, message: "An error occurred while uploading project template" })
    })

    const deleteReportTemplateResult = useMutation({
        mutationFn: async (fileName) => await deleteReportTemplate(fileName, currentProject.id),
        onSuccess: () => {
            addInfo("Template deleted");
            queryClient.invalidateQueries({ queryKey: ['fetchReportTemplates', currentProject.id] })
        },
        onError: (error) => addError({ ...error.response.data, message: "An error occurred while deleting the template!" })
    })

    const updateItemsMappingMutation = useMutation({
        mutationFn: async (items) => await updateItems(currentProject.id, items),
        onSuccess: () => {
            addInfo(`Items mapping updated successfully`);
            queryClient.invalidateQueries({ queryKey: ['fetchItems', currentProject.id] })
        },
        onError: (error) => addError({ ...error.response.data, message: "An error occurred while updating items mapping!" })
    })

    const updateProjectMutation = useMutation({
        mutationFn: async (project) => await updateProject(currentProject.id, project),
        onSuccess: () => {
            addInfo(`Project updated successfully`);
        },
        onError: (error) => addError({ ...error.response.data, message: "An error occurred while updating project!" })
    })

    useEffect(() => {
        if (reportTemplatesResult?.data && reportTemplatesResult?.data[0])
            setSelectedReportTemplate(reportTemplatesResult.data[0]);
    }, [reportTemplatesResult.data])

    useEffect(() => {
        if (itemsResult?.data)
            setItemsMapping(itemsResult.data);
    }, [itemsResult.data])

    useEffect(() => {
        if (!currentProject?.id)
            navigate("/")
    }, [currentProject?.id]);

    useEffect(() => {
        if (!parametersSimaproResult?.data) {
            parametersSimaproResult.refetch();
        }
    }, []);

    const handleTemplateChange = (e) => {
        templateFileToUpload.current = e.target.files[0];
    }

    const handleTemplateUpload = () => {
        if (templateFileToUpload.current) {
            const fileProps = templateFileToUpload.current.name.split('.');
            const fileExtension = fileProps.pop();
            const blobData = new Blob([templateFileToUpload.current], { type: templateFileToUpload.current.type })
            const filename = `${currentProject.id}_${fileProps[0]}.${fileExtension}`;

            let formData = new FormData();
            formData.append("fileForm", blobData, filename);
            uploadReportTemplateResult.mutate(formData);
        }
    }

    const errorMessage = (message, data) => {
        setFileIsValid(false)
        return (
            <>
                <div className="fw-bold text-decoration-underline text-danger">
                    {message}
                </div>
                {data && <><br />{data}</>}
            </>
        )
    }

    const validateMapperInput = (mapperObject) => {
        for (const header of ['name', 'factor', 'bomName']) {
            if ((header === 'factor' && mapperObject[header] === null) || (header !== 'factor' && !mapperObject[header])) {
                mapperObject[header] = errorMessage("Mandatory", mapperObject[header])
            } else if (header.includes('bomName') && mapperObject[header] && !parametersSimaproResult.data.map(parameter => parameter.name).includes(mapperObject[header])) {
                mapperObject[header] = errorMessage("Doesn't exist in SimaPro", mapperObject[header])
            }
        }
        return mapperObject
    }

    const handleSelectMapperCsv = (event) => {
        Papa.parse(event.target.files[0], {
            header: true,
            skipEmptyLines: 'greedy',
            delimiter: ";",
            complete: function (results) {
                setItemsMapping([])
                const csvHeaders = Object.keys(results.data[0]);
                const missingHeaders = ['ItemNo', 'WeightItem', 'SimaProParameter'].filter(
                    (header) => !csvHeaders.map(csvheader => csvheader.toLowerCase()).includes(header.toLowerCase())
                );

                if (missingHeaders.length > 0) {
                    addWarning(
                        <>
                            {"Missing headers:"} <br />
                            {missingHeaders.map(header =>
                                <div>
                                    {header} <br />
                                </div>)}
                        </>)
                    setItemsMapping([]);
                    setFileIsValid(false)

                    return;
                }

                const transformedData = results.data.map(element => (
                    {
                        name: String(element.ItemNo),
                        factor: isNaN(parseFloat(String(element.WeightItem)?.replace(",", ".")))
                            ? null
                            : parseFloat(String(element.WeightItem)?.replace(",", ".")),
                        bomName: element?.SimaProParameter
                    }))

                setFileIsValid(true);

                const validatedMapperData = transformedData.map(row => validateMapperInput(row));

                setItemsMapping(validatedMapperData);
            },
        });
    }

    const handleUpdateItemsMapper = () => {
        sidelistMapperFileRef.current.value = null;
        const updatedItemsMapping = itemsMapping.map(item => ({ ...item, bomName: item.bomName.match(parameterParsingRegex)[3] }))

        setItemsMapping(updatedItemsMapping)
        updateItemsMappingMutation.mutate(updatedItemsMapping)
    }

    const handleProjectUpdate = () => {
        updateProjectMutation.mutate(currentProject);
    }

    const handleMasterTemplateCheck = (event, index) => {
        let selectedTemplates = currentProject?.masterTemplates
            ? currentProject?.masterTemplates?.split('|') ?? []
            : []

        const updatedMasterTemplate = masterTemplateNamesResult.data.filter(masterTemplate => masterTemplate !== 'MasterTemplate.docx')[index]

        if (event.target.checked)
            selectedTemplates.push(updatedMasterTemplate)
        else {
            selectedTemplates = selectedTemplates.filter(name => name !== updatedMasterTemplate);
        }

        setCurrentProject({ ...currentProject, masterTemplates: selectedTemplates?.length > 0 ? selectedTemplates.join('|') : null });
    }

    return (
        <>
            <Container>
                <Row className="d-flex flex-row justify-content-center">
                    <Col className="px-4 justify-content-center"
                        xs={12}
                        sm={12}
                        md={12}
                        xl={6}
                        xxl={6}>
                        <div className="d-flex justify-content-center fw-bold h3">
                            <span >Project configuration</span>
                        </div>
                        <hr />
                        <Form className="mt-5">
                            <Form.Group as={Row} className="mb-3" controlId="formFile">
                                <Form.Label as={Col} sm="4" className="flex fw-bold sb-3 d-flex align-items-center">Add report template
                                </Form.Label>
                                <Col sm="8">
                                    <div className="hstack">
                                        <Form.Control className="me-3" type="file" accept=".docx" onChange={handleTemplateChange} />
                                        <Button variant="outline-dark border-0"
                                            onClick={handleTemplateUpload}
                                            disabled={uploadReportTemplateResult.isLoading}
                                        >
                                            {uploadReportTemplateResult.isLoading
                                                ? <Spinner animation="border" />
                                                : <i className="fs-4 bi-file-earmark-arrow-up"></i>}
                                        </Button>
                                    </div>
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="reportTemplate" >
                                <Form.Label as={Col} sm="4" className="flex fw-bold sb-3 d-flex align-items-center">Project templates</Form.Label>
                                <Col sm="8">
                                    {!!reportTemplatesResult?.data &&
                                        <div className="hstack">
                                            <Form.Select
                                                aria-label="Select report template"
                                                className="me-3"
                                                onChange={(e) => { setSelectedReportTemplate(e.target.value) }}
                                                value={selectedReportTemplate ?? ''}
                                            >
                                                {reportTemplatesResult?.data?.map((templateName, index) =>
                                                    <option key={index} value={templateName} >
                                                        {templateName.substring(templateName.indexOf('_') + 1, templateName.lastIndexOf('.'))}
                                                    </option>)}
                                            </Form.Select>
                                            <Button sm="1" variant="outline-dark border-0"
                                                onClick={() => { reportTemplateResult.refetch() }}
                                                disabled={!selectedReportTemplate}
                                            >
                                                {uploadReportTemplateResult.isLoading
                                                    ? <Spinner animation="border" />
                                                    : <i className="fs-4 bi-file-earmark-arrow-down" />}
                                            </Button>
                                            <Button sm="1" variant="outline-dark border-0"
                                                onClick={() => { deleteReportTemplateResult.mutate(selectedReportTemplate) }}
                                                disabled={!selectedReportTemplate}
                                            >
                                                {uploadReportTemplateResult.isLoading
                                                    ? <Spinner animation="border" />
                                                    : <i className="fs-4 bi-trash" />}
                                            </Button>
                                        </div>}
                                </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-3" controlId="selectMasterTemplate" >
                                <Form.Label as={Col} sm="4" className="flex fw-bold sb-3 d-flex align-items-center">Extra master templates</Form.Label>
                                <Col sm="8">
                                    {!masterTemplateNamesResult?.data
                                        ? <Loading />
                                        : <div className="hstack">
                                            <Form>
                                                {masterTemplateNamesResult.data.filter(masterTemplate => masterTemplate !== 'MasterTemplate.docx').map((masterTemplate, index) => (
                                                    <Form.Check
                                                        label={masterTemplate}
                                                        type="checkbox"
                                                        id={masterTemplate}
                                                        checked={currentProject?.masterTemplates?.split('|')?.includes(masterTemplate)}
                                                        onChange={(event) => handleMasterTemplateCheck(event, index)}
                                                    />
                                                ))}
                                            </Form>
                                            <Button className="ms-auto"
                                                variant="outline-dark border-0"
                                                onClick={handleProjectUpdate}
                                                disabled={masterTemplateNamesResult.isLoading}
                                            >
                                                {masterTemplateNamesResult.isLoading
                                                    ? <Spinner animation="border" />
                                                    : <i className="fs-4 bi-floppy"></i>}
                                            </Button>
                                        </div>}
                                </Col>
                            </Form.Group>
                            <Form.Group>
                                <Row >
                                    <Form.Label className="flex fw-bold sb-3 d-flex align-items-center" as={Col} sm="4">Mapper file</Form.Label>
                                    <Col sm="8">
                                        <div className="hstack">
                                            <Form.Control
                                                className="me-3"
                                                type="file"
                                                accept=".csv"
                                                ref={sidelistMapperFileRef}
                                                onChange={handleSelectMapperCsv}
                                                onClick={(event) => event.target.value = null}
                                            />
                                            <Button variant="outline-dark border-0"
                                                onClick={handleUpdateItemsMapper}
                                                disabled={sidelistMapperFileRef?.current?.files?.length === 0 || !fileIsValid}
                                            >
                                                <i className="fs-4 bi-file-earmark-arrow-up"></i>
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Form>
                        <div className="d-flex justify-content-center fw-bold h3 mt-5">
                            {sidelistMapperFileRef?.current?.files?.length === 0
                                ? < span > Mapper preview from database </span>
                                : < span > Mapper preview from file </span>}

                        </div>
                        <hr />
                        {!itemsMapping
                            ? <Loading />
                            : <Table className="mt-5 table-responsive" variant="light">
                                <thead>
                                    <tr>
                                        <th className="col-4"> Item name </th>
                                        <th className="col-6"> BOM name </th>
                                        <th className="col-2"> Factor </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {itemsMapping?.map((item) => (
                                        <tr key={item?.id}>
                                            <td className="col-4">{item?.name}</td>
                                            <td className="col-6">{item?.bomName}</td>
                                            <td className="col-2">{item?.factor}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>}
                    </Col>
                </Row>
            </Container>
        </>
    );
}