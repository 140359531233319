import { GlobalContext } from './contexts/GlobalContext';
import { Loading } from './Loading';
import React, { useEffect, useContext, useState } from 'react';
import { Form, Col, Row, Button, Image, OverlayTrigger, Tooltip, Modal } from "react-bootstrap";
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import fileDownload from 'js-file-download'
import { fetchImageFile, fetchReportTemplates, fetchProducts, fetchImageNames, addProduct, deleteProduct, uploadImage, updateProduct, deleteImage } from '../Queries'
import { useNotifications } from '@de/react-bootstrap-notifier';

export function Product({ parameters, combineWithSimaproParameters, isValidationEnabled, setIsValidationEnabled }) {

    const { currentProject, currentProduct, setCurrentProduct } = useContext(GlobalContext);
    const { addInfo, addWarning, addError } = useNotifications();
    const [imageBlob, setImageBlob] = useState('');
    const [enablePrviousProducts, setEnablePrviousProducts] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const queryClient = useQueryClient()
    const productsResult = useQuery({ queryKey: ['fetchProducts', currentProject.id], queryFn: () => fetchProducts(currentProject.id) })
    const imageFilenamesResult = useQuery({ queryKey: ['fetchImageNames', currentProject.id], queryFn: () => fetchImageNames(currentProject.id), enabled: !!currentProject?.id })
    const reportTemplatesResult = useQuery({ queryKey: ['fetchReportTemplates', currentProject.id], queryFn: () => fetchReportTemplates(currentProject.id), enabled: !!currentProject?.id })
    const imageFileResult = useQuery({ queryKey: ['fetchImageFile', currentProduct?.imageFilename], queryFn: () => fetchImageFile(currentProduct?.imageFilename), enabled: !!currentProduct?.imageFilename, refetchOnWindowFocus: false })

    const uploadImageMutation = useMutation({
        mutationFn: async (formData) => await uploadImage(formData),
        onSuccess: () => {
            addInfo("Image added successfully");
            queryClient.invalidateQueries({ queryKey: ['fetchImageNames', currentProject.id] })
            imageFilenamesResult.refetch();
        },
        onError: (error) => addError({ ...error.response.data, message: "An error occurred while uploading image!" })
    })

    const addProductMutation = useMutation({
        mutationFn: async (product) => await addProduct({ ...product, parameters: parameters }),
        onSuccess: (_, variables) => {
            setIsValidationEnabled(false)
            setEnablePrviousProducts(true)
            addInfo(`Product "${variables.name}" added successfully`);
            queryClient.invalidateQueries({ queryKey: ['fetchProducts', currentProject.id] })
        },
        onError: (error) => addError({ ...error.response.data, message: "An error occurred while adding product!" })
    })

    const updateProductMutation = useMutation({
        mutationFn: async (product) => await updateProduct({ ...product, parameters: parameters }),
        onSuccess: (_, variables) => {
            setIsValidationEnabled(false)
            addInfo(`Product "${variables.name}" updated successfully`);
            queryClient.invalidateQueries({ queryKey: ['fetchProducts', currentProject.id] })
        },
        onError: (error) => addError({ ...error.response.data, message: "An error occurred while updating product!" })
    })

    const deleteProductMutation = useMutation({
        mutationFn: async (product) => await deleteProduct(product.id),
        onSuccess: (_, variables) => {
            addInfo(`Product "${variables.name}" deleted successfully`);
            setCurrentProduct(productsResult.data.find(prod => prod.id !== deleteProductMutation.variables))
            queryClient.invalidateQueries({ queryKey: ['fetchProducts', currentProject.id] })
        },
        onError: (error) => addError({ ...error.response.data, message: "An error occurred while deleteing product!" })
    })

    const deleteImageResult = useMutation({
        mutationFn: async (fileName) => await deleteImage(fileName),
        onSuccess: () => {
            addInfo("Image deleted");
            setImageBlob('')
            queryClient.invalidateQueries({ queryKey: ['fetchImageNames', currentProject.id] })
        },
        onError: () => (error) => addError({ ...error.response.data, message: "An error occurred while deleteing image!" })
    })

    useEffect(() => {
        if (imageFileResult.data) {
            setImageBlob(imageFileResult.data)
        }
    }, [imageFileResult.data]);

    useEffect(() => {
        if (productsResult.data && currentProduct?.name) {
            setCurrentProduct(productsResult.data.find(product => product.name === currentProduct.name));
        }
    }, [productsResult.data]);

    const handleLoadCopy = () => {
        setEnablePrviousProducts(false);
        setCurrentProduct({ ...currentProduct, id: 0, name: `${currentProduct.name} - Copy`, parameters: parameters });
    };

    const handleChangeImage = (e) => {
        const file = e.target.files[0];
        const fileProps = file.name.split('.');
        const filename = fileProps[0]
        const fileExtension = fileProps.pop();

        if (file.size > 2097152) {
            addWarning('Canceled. Maximum file size exceeded. Upload an image smaller than 2 MB.');
            e.target.value = null
            return;
        }

        const blobData = new Blob([file], { type: file.type })
        setImageBlob(blobData)

        let formData = new FormData();
        formData.append("fileForm", blobData, `${currentProject.id}_${filename}.${fileExtension}`);

        setCurrentProduct({ ...currentProduct, imageFilename: `${currentProject.id}_${filename}.${fileExtension}` })
        uploadImageMutation.mutate(formData);
    }

    const handleDownloadImage = () => {
        const fileType = `image/${currentProduct.imageFilename.split('.').pop()}`
        const imageBlob = new Blob([imageFileResult.data], { type: fileType })
        fileDownload(imageBlob, currentProduct.imageFilename.substring(currentProduct.imageFilename.indexOf('_') + 1));
    };

    const handleChangeImageSelection = (event) => {
        setCurrentProduct({ ...currentProduct, imageFilename: event.target.value })
    }

    const contentOrEmptyString = (value) => {
        return value ? value : ""
    }
    const handleProductChange = (event) => {        
        const prod = productsResult.data.find(product => product.name === event.target.value)
        if (!prod?.imageFilename || !imageFilenamesResult?.data?.includes(prod?.imageFilename)) 
            setImageBlob('')
        setCurrentProduct(prod)
    }

    const getSimplifiedFileName = (filename) => {
        if (filename)
            return filename.substring(filename.indexOf('_') + 1, filename.lastIndexOf('.'))
    }

    const handleEnablePreviousProductsChange = () => {
        setIsValidationEnabled(false)
        const currentState = !enablePrviousProducts
        setEnablePrviousProducts(currentState)

        if (currentState === true) {
            setCurrentProduct(productsResult.data[0])
        }
        else {
            setCurrentProduct(null);
            combineWithSimaproParameters([])
        }
    }

    const handleDeleteProduct = async (product) => {
        setShowDeleteModal(false);
        deleteProductMutation.mutate(product)
    }

    return (
        <div>
            <Form>
                <Form.Group as={Row} controlId="checkbox">
                    <Form.Check inline label="Use previous products" type="switch" className="fw-bold h4 mx-3 mb-5"
                        checked={enablePrviousProducts}
                        onChange={handleEnablePreviousProductsChange}
                        disabled={!productsResult?.data || productsResult.data.length === 0} />
                </Form.Group>

                <Form.Group as={Row} className="fw-bold h3 mb-5 save-product-button" controlId="publicationDate" >
                    {enablePrviousProducts === false ?
                        (<Button variant="success" className="rounded-pill" disabled={!currentProduct?.name || addProductMutation.isLoading || productsResult?.data?.find(p => p.name === currentProduct?.name)} onClick={() => { addProductMutation.mutate({ ...currentProduct, projectId: currentProject.id }) }} >
                            Save product
                        </Button>) :
                        (<Button variant="success" className="rounded-pill" disabled={!currentProduct?.name || updateProductMutation.isLoading} onClick={() => { updateProductMutation.mutate(currentProduct) }} >
                            Save product
                        </Button>)
                    }
                </Form.Group>

                {enablePrviousProducts === true ?

                    (<Form.Group as={Row} className="p-1" controlId="productName">
                        <Form.Label column sm="4" className="fw-bold">Product name</Form.Label>
                        <Col sm="6">
                            <div className="hstack">

                                <Form.Select aria-label="Select product"
                                    value={contentOrEmptyString(currentProduct?.name)}
                                    onChange={handleProductChange}
                                    isInvalid={!currentProduct?.name}>
                                    {productsResult.isLoading
                                        ? <Loading />
                                        : productsResult.data.map((product) =>
                                            <option key={product?.id} value={contentOrEmptyString(product?.name)}>
                                                {contentOrEmptyString(product?.name)}
                                            </option>)}
                                </Form.Select>
                                <Form.Control.Feedback type="invalid">
                                    Add product
                                </Form.Control.Feedback>

                                <OverlayTrigger placement="top" overlay={<Tooltip id="clone-product">Load copy</Tooltip>}>
                                    <Button variant="outline-dark border-0 mx-3" onClick={handleLoadCopy} >
                                        <i className="fs-4 bi-copy" />
                                    </Button>
                                </OverlayTrigger>

                                <OverlayTrigger placement="top" overlay={<Tooltip id="delete-product-tooltip">Delete product</Tooltip>}>
                                    <Button variant="outline-dark border-0 mx-3" onClick={() => setShowDeleteModal(true)} >
                                        <i className="fs-4 bi-trash" />
                                    </Button>
                                </OverlayTrigger>
                                <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Delete product</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <p>{`Are you sure you want to remove the product: ${currentProduct.name} ?`}</p>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
                                            Cancel
                                        </Button>
                                        <Button variant="dark" onClick={() => handleDeleteProduct(currentProduct)}>
                                            Ok
                                        </Button>
                                    </Modal.Footer>
                                </Modal>

                            </div>
                        </Col>
                    </Form.Group>) :

                    (<Form.Group as={Row} className="p-1" controlId="name">
                        <Form.Label column sm="4" className="fw-bold">Product name</Form.Label>
                        <Col sm="8">
                            <Form.Control type="text" className="p-2"
                                value={currentProduct?.name}
                                onChange={(e) => {
                                    setCurrentProduct({ ...currentProduct, name: e.target.value })
                                }}
                                isInvalid={!currentProduct?.name || productsResult?.data?.find(p => p.name === currentProduct?.name)}
                            />
                            <Form.Control.Feedback type="invalid">
                                {(currentProduct?.name)
                                    ? ("Product already exists")
                                    : ("Enter value")
                                }
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>)
                }

                <Form.Group as={Row} className="p-1" controlId="identification">
                    <Form.Label column sm="4" className="fw-bold">Product identification</Form.Label>
                    <Col sm="8">
                        <Form.Control type="text" className="p-2"
                            value={contentOrEmptyString(currentProduct?.identification)}
                            onChange={(e) => {
                                setCurrentProduct({ ...currentProduct, identification: e.target.value })
                            }}
                            isInvalid={!currentProduct?.identification && isValidationEnabled}
                        />
                        <Form.Control.Feedback type="invalid">
                            Enter value
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="p-1" controlId="description">
                    <Form.Label column sm="4" className="fw-bold">Product description</Form.Label>
                    <Col sm="8">
                        <Form.Control as="textarea" rows={6} className="p-2"
                            value={contentOrEmptyString(currentProduct?.description)}
                            onChange={(e) => {
                                setCurrentProduct({ ...currentProduct, description: e.target.value })
                            }}
                            isInvalid={!currentProduct?.description && isValidationEnabled}
                        />
                        <Form.Control.Feedback type="invalid">
                            Enter value
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="p-1" controlId="cpc">
                    <Form.Label column sm="4" className="fw-bold">UN CPC Code</Form.Label>
                    <Col sm="8">
                        <Form.Control type="text" className="p-2"
                            value={contentOrEmptyString(currentProduct?.unCpcCode)}
                            onChange={(e) => {
                                setCurrentProduct({ ...currentProduct, unCpcCode: e.target.value })
                            }}
                            isInvalid={!currentProduct?.unCpcCode && isValidationEnabled}
                        />
                        <Form.Control.Feedback type="invalid">
                            Enter value
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="p-1" controlId="epdRegistrationNumber" >
                    <Form.Label column sm="4" className="fw-bold sb-3">EPD registration number</Form.Label>
                    <Col sm="8">
                        <Form.Control type="text"
                            min={0}
                            value={contentOrEmptyString(currentProduct?.epdRegistrationNumber)}
                            onChange={(e) => setCurrentProduct({ ...currentProduct, epdRegistrationNumber: e.target.value })}
                            isInvalid={!currentProduct?.epdRegistrationNumber && isValidationEnabled}
                        />
                        <Form.Control.Feedback type="invalid">
                            Enter value
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="p-1" controlId="publicationDate" >
                    <Form.Label column sm="4" className="fw-bold sb-3">Publication date</Form.Label>
                    <Col sm="8">
                        <Form.Control type="date"
                            min={0}
                            value={contentOrEmptyString(currentProduct?.publicationDate)}
                            onChange={(e) => {
                                setCurrentProduct({ ...currentProduct, publicationDate: e.target.value })
                            }}
                            isInvalid={!currentProduct?.publicationDate && isValidationEnabled}
                        />
                        <Form.Control.Feedback type="invalid">
                            Enter value
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                {currentProject?.masterTemplates &&
                    <Form.Group as={Row} className="p-1" controlId="selectMasterTemplate" >
                        <Form.Label column sm="4" className="fw-bold sb-3">Select master template</Form.Label>
                        <Col sm="8">
                            <Form.Select aria-label="Select master template"
                                onChange={(e) => setCurrentProduct({ ...currentProduct, masterTemplateFilename: e.target.value })}
                                value={currentProject?.masterTemplates.split('|').includes(currentProduct?.masterTemplateFilename)
                                    ? currentProduct?.masterTemplateFilename
                                    : 'DEFAULT'}
                            >
                                <option key='DEFAULT' value='MasterTemplate.docx'>MasterTemplate.docx</option>
                                {currentProject?.masterTemplates.split('|').map((filename) =>
                                    <option key={filename} value={filename}>
                                        {filename}
                                    </option>)}
                            </Form.Select>
                        </Col>
                    </Form.Group>}

                <Form.Group as={Row} className="p-1" controlId="selectReportTemplate" >
                    <Form.Label column sm="4" className="fw-bold sb-3">Report template</Form.Label>
                    <Col sm="8">
                        {reportTemplatesResult?.isLoading
                            ? <Loading />
                            : <Form.Select aria-label="Select report template"
                                onChange={(e) => setCurrentProduct({ ...currentProduct, reportTemplateFilename: e.target.value })}
                                value={reportTemplatesResult?.data?.includes(currentProduct?.reportTemplateFilename)
                                    ? currentProduct?.reportTemplateFilename
                                    : 'DEFAULT'}
                                isInvalid={(!currentProduct?.reportTemplateFilename || !reportTemplatesResult?.data?.includes(currentProduct?.reportTemplateFilename)) && isValidationEnabled}
                            >

                                <option key='DEFAULT' value='DEFAULT' disabled>Choose template ...</option>
                                {reportTemplatesResult?.data?.map((filename) =>
                                    <option key={filename} value={filename}>
                                        {getSimplifiedFileName(filename)}
                                    </option>)}
                            </Form.Select>}
                        <Form.Control.Feedback type="invalid">
                            Select report template
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>

                <Form.Group as={Row} className="p-1" controlId="images" >
                    <Form.Label column sm="4" className="fw-bold sb-3">Image</Form.Label>
                    <Col sm="5">
                        {imageFilenamesResult?.isLoading
                            ? <Loading />
                            : <div className="hstack">
                                <Form.Select aria-label="Select image"
                                    onChange={handleChangeImageSelection}
                                    value={imageFilenamesResult?.data?.includes(currentProduct?.imageFilename)
                                        ? currentProduct?.imageFilename
                                        : 'DEFAULT'}
                                    isInvalid={(!currentProduct?.imageFilename || !imageFilenamesResult?.data?.includes(currentProduct?.imageFilename)) && isValidationEnabled}
                                >
                                    <option key='DEFAULT' value='DEFAULT' disabled>Choose image ...</option>
                                    {imageFilenamesResult?.data?.map((filename) =>
                                        <option key={filename} value={filename}>
                                            {getSimplifiedFileName(filename)}
                                        </option>)}
                                </Form.Select>
                                <OverlayTrigger placement="top" overlay={<Tooltip id="Delete image">Delete image</Tooltip>}>
                                    <Button variant="outline-dark border-0" onClick={() => { deleteImageResult.mutate(currentProduct?.imageFilename) }} >
                                        <i className="fs-4 bi-trash"></i>
                                    </Button>
                                </OverlayTrigger>
                            </div>}
                        <Form.Control.Feedback type="invalid">
                            Select image
                        </Form.Control.Feedback>

                        <div className="hstack">
                            <Form.Control type="file" accept=".bmp, .gif, .ico, .jpg, .jpeg, .png, .tif, .tiff, .wmf;" onChange={handleChangeImage} />
                            <OverlayTrigger placement="top" overlay={<Tooltip id="Download image">Download image</Tooltip>}>
                                <Button variant="outline-dark border-0" onClick={handleDownloadImage} >
                                    <i className="fs-4 bi-file-earmark-arrow-down"></i>
                                </Button>
                            </OverlayTrigger>
                        </div>
                    </Col>
                    <Col sm="3">
                        {imageBlob !== ''
                            ? <Image src={URL.createObjectURL(imageBlob)} className="w-75" />
                            : <></>}
                    </Col>
                </Form.Group>
            </Form>
        </div>
    );
}