import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

export function ClimateChangeChart(props) {

    const [climate, setClimate] = useState({
        labels: [],
        datasets: [],
    });
    const [height, setHeight] = useState("700px");

    const options = {
        indexAxis: 'y',
        animation: false,
        plugins: {
            legend: {
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'circle'
                }
            },
            title: {
                display: true,
                text: `${props.title}`,
            },
        },
        scales: {
            y: {
                ticks: {
                    autoSkip: false
                }
            }
        },
        maintainAspectRatio: false,
        responsive: true,
    };

    const resizeChart = (seriesCount) => {
        if (seriesCount < 20)
            setHeight("700px");
        else if (seriesCount < 30)
            setHeight("1000px");
        else if (seriesCount < 40)
            setHeight("1300px");
        else if (seriesCount < 50)
            setHeight("1600px");
        else
            setHeight("2000px");
    };

    useEffect(() => {
        if (props.data) {
            resizeChart(props.data.length);
            setClimate({
                labels: props.data.map((item) => item.product),
                datasets: [
                    {
                        label: `${props.lable}`,
                        backgroundColor: "blue",
                        data: props.data.map((item) => item.value),
                        barPercentage: 0.4
                    }
                ]
            })
        }
    }, [props.data]);

    return (
        <div className="invisible-chart" style={{ width: "1400px", height: height }} >
            <Bar data={climate} options={options} ref={props.chartRef} />
        </div>
    )
}