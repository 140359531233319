import { Route, Routes } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import AppRoutes from './AppRoutes';
import { Layout } from './components/Layout';
import { useMsal, useMsalAuthentication, AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { InteractionType } from '@azure/msal-browser';
import { loginRequest } from './identity/authProvider'
import { callMsGraph } from './components/Graph'
import { Loading } from './components/Loading';
import './custom.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

export default function App() {
    const [loading, setloading] = useState(false);
    const { login, error, result } = useMsalAuthentication(InteractionType.Silent, loginRequest);
    const { instance, accounts } = useMsal();
    const [queryClient] = React.useState(() => new QueryClient());

    useEffect(() => {
        if (error && !loading) {
            setloading(true);
            login(InteractionType.Redirect, { scopes: loginRequest.scopes });
        }

    }, [error, login, result]);

    useEffect(() => {
        if (accounts.length > 0) {
            instance
                .acquireTokenSilent({
                    ...loginRequest,
                    account: accounts[0],
                })
                .then((response) => {
                    callMsGraph(response.accessToken);
                }).catch(error => {
                    login(InteractionType.Redirect, { scopes: loginRequest.scopes });
                });
        }
    }, [login, result])

    return (
        <div>
            <AuthenticatedTemplate>
                <QueryClientProvider client={queryClient}>
                    <Layout>
                        <Routes>
                            {AppRoutes.map((route, index) => {
                                const { element, ...rest } = route;
                                return <Route key={index} {...rest} element={element} />;
                            })}
                        </Routes>
                    </Layout>
                </QueryClientProvider>
            </AuthenticatedTemplate >
            <UnauthenticatedTemplate>
                <Loading />
            </UnauthenticatedTemplate>
        </div>
    );
}