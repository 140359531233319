//import api from 'api';
import * as api from './ApiWrapper'

//USERS ENDPOINTS
export const fetchUsers = async () => {
    const res = await api.get(`users`);
    return res.data;
}

export const fetchUserSelfData = async () => {
    const res = await api.get(`users/selfData`);
    return res.data;
}

export const fetchUsersProjectsRoles = async () => {
    const res = await api.get(`users/usersProjectsRoles`);
    return res.data;
}

export const fetchRoles = async () => {
    const res = await api.get(`users/roles`);
    return res.data;
}

export const fetchUserProjectsRoles = async () => {
    const res = await api.get(`users/userProjectsRoles`)
    return res.data;
}

export const addProjectUser = async (input) => {
    return await api.post(`users/projectUser?userId=${input.userId}&projectId=${input.projectId}&roleId=${input.roleId}`);
}

export const deleteProjectUser = (input) => {
    return api.delete(`users/projectUser?userId=${input.userId}&projectId=${input.projectId}`);
}

export const fetchParameters = async (productId) => {
    const res = await api.get(`project/parameters?productId=${productId}`)
    return res.data;
}

export const fetchProducts = async (projectId) => {
    const res = await api.get(`project/products?projectId=${projectId}`)
    return res.data
}

export const fetchReportTemplates = async (projectId) => {
    const res = await api.get(`project/reportTemplates?projectId=${projectId}`);
    return res.data;
}

export const fetchMasterTemplateNames = async () => {
    const res = await api.get(`project/masterTemplates`);
    return res.data;
}

//SIMAPRO ENDPOINTS
export const updateProjects = async () => {
    const res = await api.post(`simapro/projects`);
    return res.data;
}

export const generateSimaproReport = (input) => {
    const payload = {
        "parameters": input.parameters,
        "product": input.product
    }

    return api.post(`simapro/generate?projectId=${input.projectId}&reportId=${input.reportId}`, payload);
}

export const fetchSimaproParameters = async (projectId) => {

    const res = await api.get(`simapro/parametersList?projectId=${projectId}`);
    return res.data;
}

//PROJECT ENDPOINTS
export const fetchImageNames = async (projectId) => {
    const res = await api.get(`project/images?projectId=${projectId}`);
    return res.data;
}

export const fetchImageFile = async (filename) => {
    const res = await api.getFileOctet(`project/image?filename=${filename}`);
    return res.data;
}

export const fetchInputDataReport = async (projectId) => {
    const res = await api.getFileForm(`project/inputDataReport?projectId=${projectId}`)
    return res.data;
}

export const fetchClimateImpactReport = async (projectId) => {
    const res = await api.getFileForm(`project/climateImpactReport?projectId=${projectId}`)
    return res.data;
}

export const fetchClimateImpactCsvReport = async (projectId) => {
    const res = await api.getFileForm(`project/climateImpactCsvReport?projectId=${projectId}`)
    return res.data;
}

export const fetchWeightingCsvReport = async (projectId) => {
    const res = await api.getFileForm(`project/weightingCsvReport?projectId=${projectId}`)
    return res.data;
}

export const fetchMachineReadableReport = async (projectId) => {
    const res = await api.getFileForm(`project/machineReadableReport?projectId=${projectId}`)
    return res.data;
}

export const fetchWordReport = async (projectId) => {
    const res = await api.getFileOctet(`project/reportWord?projectId=${projectId}`);
    return res.data;
}

export const fetchPdfReport = async (projectId) => {
    const res = await api.getFileOctet(`project/reportPdf?projectId=${projectId}`);
    return res.data;
}

export const generateInputDataReport = (formData) => {
    return api.postFileForm(`project/inputDataReport`, formData);
}

export const uploadClimateImpactReport = (formData) => {
    return api.postFileForm(`project/climateImpactReport`, formData);
}

export const updateParameters = (input) => {
    return api.post(`project/parameters?productId=${input.productId}`, input.parameters)
}

export const syncParametersWithSimapro = (input) => {
    return api.post(`project/parametersSync?projectId=${input.projectId}`, input.parameters)
}

export const addProduct = (product) => {
    return api.post(`project/product`, product);
}

export const updateProduct = (product) => {
    return api.put(`project/product`, product);
}

export const deleteProduct = (productId) => {
    return api.delete(`project/product?productId=${productId}`);
}

export const fetchProjects = async () => {
    const res = await api.get(`project/projects`);
    return res.data;
}

export const fetchUserProjects = async () => {
    const res = await api.get(`project/userProjects`);
    return res.data;
}

export const fetchReportTemplate = async (fileName, projectId) => {
    const res = await api.getFileOctet(`project/reportTemplate?projectId=${projectId}&fileName=${fileName}`);
    return res.data;
}

export const uploadReportTemplate = (data, projectId) => {
    return api.postFileOctet(`project/reportTemplate?projectId=${projectId}`, data);
}

export const deleteReportTemplate = (fileName, projectId) => {
    return api.delete(`project/reportTemplate?projectId=${projectId}&fileName=${fileName}`);
}

export const uploadMasterTemplate = (data) => {
    return api.postFileOctet(`project/masterTemplate`, data);
}

export const uploadImage = (data) => {
    return api.postFileOctet(`project/image`, data);
}

export const deleteImage = (fileName) => {
    return api.delete(`project/image?fileName=${fileName}`);
}

export const fetchItems = async (projectId) => {
    const res = await api.get(`project/items?projectId=${projectId}`)
    return res.data
}

export const updateItems = (projectId, items) => {
    return api.post(`project/items?projectId=${projectId}`, items)
}

export const updateProject = (projectId, project) => {
    return api.patch(`project/${projectId}`, project);
}