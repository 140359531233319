import React from 'react';
import { Container, Spinner, Row } from 'react-bootstrap';

export function Loading() {
    return (
        <Container fluid className="d-flex justify-content-center overflow-hidden">
            <Row >
                <div>
                    <Spinner role="status" className="mx-3 align-middle"></Spinner>
                    <span className="align-middle">Loading ...</span>
                </div>
            </Row>
        </Container>
    );
}