export const getGroupedExtParameters = (parameters) => {
    const filteredTable = parameters.filter(param => param.parameterTypeName === 'EXT')

    const groupedObject = Object.groupBy(filteredTable.map(x => {
        x.groupKey = x.parameterGroup

        return { ...x }
    }), ({ groupKey }) => groupKey)

    const groupedTable = []

    for (const [key, value] of Object.entries(groupedObject)) {
        groupedTable.push({ key: key, value: value });
    }

    return groupedTable
};

export const getExtGroupValue = (group) => {
    const groupValue = group.value.find(v => v.calculatedValue === 1)
        ? group.value.find(v => v.calculatedValue === 1).name
        : 'DEFAULT'

    return groupValue;
}

export const getDateTimeNow = () => {
    const currentDate = new Date();
    const formattedDate = `${('0' + currentDate.getDate()).slice(-2)}-${('0' + (currentDate.getMonth() + 1)).slice(-2)}-${currentDate.getFullYear()} ${('0' + currentDate.getHours()).slice(-2)}:${('0' + currentDate.getMinutes()).slice(-2)}`;
    return formattedDate;
}