import React, { useState, createContext } from "react";

export const GlobalContext = createContext();

export const GlobalProvider = (props) => {
    const [user, setUser] = useState(
        {
            id: 0,
            username: null,
            projectId: null,
            projectName: null,
            role: null,
            isAdmin: false,
        });

    const [currentProject, setCurrentProject] = useState({ id: 0, name: null, description: null, masterTemplateFilename: null, });

    const [currentProduct, setCurrentProduct] = useState({ usePreviousProducts: false });

    const clearContextData = () => {
        setUser({ id: 0, username: null, projectId: null, isAdmin: false, role: null });
        setCurrentProduct({ usePreviousProducts: false })
        setCurrentProject({ id: 0, name: null, description: null, masterTemplateFilename: null, });        
    }

    const parameterParsingRegex = /^(\w{3})(\d*)_(.+)/

    return (
        <GlobalContext.Provider
            value={{
                parameterParsingRegex,
                user,
                setUser,
                currentProject,
                setCurrentProject,
                currentProduct,
                setCurrentProduct,
                clearContextData,
            }}
        >
            {props.children}
        </GlobalContext.Provider>
    )
}