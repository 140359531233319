/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import { useQuery } from '@tanstack/react-query'
import { Container, Button, Col, Form } from "react-bootstrap";
import { useNavigate } from 'react-router-dom';
import { GlobalContext } from './contexts/GlobalContext';
import { Loading } from './Loading';
import { useState, useEffect } from 'react';
import { fetchUserProjectsRoles, fetchUserSelfData } from '../Queries'
import './css/HomePage.css';

export function Home() {
    const navigate = useNavigate()
    const { user, setUser, setCurrentProject, setCurrentProduct } = useContext(GlobalContext);
    const [search, setSearch] = useState('');

    const userProjectsRolesResult = useQuery({ queryKey: ['fetchUserProjectsRoles'], queryFn: fetchUserProjectsRoles })
    const userSelfDataResult = useQuery({ queryKey: ['fetchUserSelfData'], queryFn: fetchUserSelfData })

    const handleProjectChange = (projectId) => {
        let data = userProjectsRolesResult?.data?.find(upr => upr.project.id === projectId)
        setCurrentProduct({ usePreviousProducts: false })
        setCurrentProject(data?.project);
        setUser({ ...data?.user, role: data.role.name });
        navigate('/inputForm')
    }

    useEffect(() => {
        if (!!userSelfDataResult?.data)
            setUser({ ...user, id: userSelfDataResult?.data?.id, isAdmin: userSelfDataResult?.data?.isAdmin, username: userSelfDataResult?.data?.login })
    }, [userSelfDataResult.data]);

    return (
        <>
            {userProjectsRolesResult.isLoading ? (
                <Loading />
            ) :
                userProjectsRolesResult.data.length > 0 ? (
                    <Container className="justify-content-center px-5">
                        <Form className="mb-2 h3">
                            <Form.Group as={Col} sm={4} controlId="projectName" className="mb-2 h3">
                                <Form.Control
                                    className="my-5 h1"
                                    size="lg"
                                    required
                                    type="text"
                                    value={search}
                                    placeholder='Search'
                                    onChange={(e) => setSearch(e.target.value)}
                                />
                            </Form.Group>
                        </Form>
                        <div className="h1 mb-4">My projects</div>
                        {userProjectsRolesResult.data.filter(upr => upr.project.name.includes(search)).map((upr, index) =>
                            <Col key={index}>
                                <Button variant="link" size="lg" className="border-0 h2" onClick={() => handleProjectChange(upr.project.id)}>{upr.project.name}</Button>
                            </Col>
                        )}
                    </Container>
                ) :
                    <div className="text-center responsive-h4 fw-semibold">
                        <i class="bi-question-circle-fill">
                            You do not have access to any projects. Please contact EPD-generator team for help.
                        </i>
                    </div>
            }
        </>
    );
}