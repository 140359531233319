import axios from 'axios';
import { loginRequestWithoutHint } from './identity/authProvider';
import { msalInstance } from './index';

const { REACT_APP_SERVER_URI } = process.env;

export const getAccount = () => {
    return get(`${REACT_APP_SERVER_URI}account`);
};

export const get = async (url, params) => {
    return msalInstance.acquireTokenSilent(loginRequestWithoutHint).then(authResult => {
        const { accessToken } = authResult;
        const headers = { Authorization: `Bearer ${accessToken}`};

        return axios.get(url, {
            headers,
            params,
        });
    });
};

export const post = async (url, data) => {
    return msalInstance.acquireTokenSilent(loginRequestWithoutHint).then(authResult => {
        const { accessToken } = authResult;
        const config = {
            headers: { Authorization: `Bearer ${accessToken}`},
            data: { ...data }
        };

        return axios.post(url, data, config);
    });
};

export const put = async (url, data) => {
    return msalInstance.acquireTokenSilent(loginRequestWithoutHint).then(authResult => {
        const { accessToken } = authResult;
        const config = {
            headers: { Authorization: `Bearer ${accessToken}`},
            data: { ...data }
        };

        return axios.put(url, data, config);
    });
};


export const patch = async (url, data) => {
    return msalInstance.acquireTokenSilent(loginRequestWithoutHint).then(authResult => {
        const { accessToken } = authResult;
        const config = {
            headers: { Authorization: `Bearer ${accessToken}` },
            data: { ...data }
        };

        return axios.patch(url, data, config);
    });
};

const del = async (url, data) => {
    return msalInstance.acquireTokenSilent(loginRequestWithoutHint).then(authResult => {
        const { accessToken } = authResult;
        const config = { headers: { Authorization: `Bearer ${accessToken}` }, data: data };

        return axios.delete(url, config);
    });
};

export const postFileForm = async (url, formData) => {

    return msalInstance.acquireTokenSilent(loginRequestWithoutHint).then(authResult => {
        const { accessToken } = authResult;
        const config = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'multipart/form-data'
            },
            data: { ...formData }
        };

        return axios.post(url, formData, config);
    });
};

export const postFileOctet = async (url, data) => {
    return msalInstance.acquireTokenSilent(loginRequestWithoutHint).then(authResult => {
        const { accessToken } = authResult;
        const config = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/octet-stream'
            },
            data: { ...data }
        };

        return axios.post(url, data, config);
    });
};

export const getFileOctet = async (url, params) => {
    return msalInstance.acquireTokenSilent(loginRequestWithoutHint).then(authResult => {
        const { accessToken } = authResult;
        const headers = {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/octet-stream',            
        };

        return axios.get(url, {
            headers,
            responseType: 'blob'
        });
    });
};

export const getFileForm = async (url, params) => {
    return msalInstance.acquireTokenSilent(loginRequestWithoutHint).then(authResult => {
        const { accessToken } = authResult;
        const headers = {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/form-data',
        };

        return axios.get(url, {
            headers,
            responseType: 'blob'
        });
    });
};


export { del as delete };